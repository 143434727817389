<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Como Funciona</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/profissional">Profissional </a></li>
                <li class="active">Como Funciona</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Conhecendo a Listen</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <ul class="accordion">
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title active" href="javascript:void(0)"><i class="bx bx-plus"></i> Como Devo proceder para me tornar um profissional parceiro e atender pela plataforma Listen?</a>
                                <p class="accordion-content show">Preencha seu cadastro com seus dados pessoais, envie imagens dos documentos solicitados e anexe seu currículo profissional. Seu cadastro será aprovado em até 24 horas.
                                    <br><br><a routerLink="/profissional/inscreva-se" class="box-btn">Inscreva-se</a></p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> Como acontecem as sessões on-line?</a>
                                <p class="accordion-content">Após fazer o login na plataforma, você terá acesso à sua agenda. Lá estarão os links para cada consulta. Basta clicar nos links que você acessará a área de atendimento. Todo o processo ocorre dentro da plataforma, em sessões de 50 minutos.</p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> Como defino os horários em que estarei disponível para atendimento?</a>
                                <p class="accordion-content">Quando for preencher seu perfil na plataforma, você assinala os horários que disponibilizará para atender seus clientes pela Listen. Os horários serão exibidos aos clientes quando eles visualizarem seu perfil.</p>
                            </li>
                            
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> Como serei informado sobre as consultas marcadas?</a>
                                <p class="accordion-content">Após a confirmação do pagamento, você e o cliente receberão um e-mail confirmando o dia e o horário e sua agenda será atualizada automaticamente. No dia da consulta, uma hora antes do horário marcado, profissional e cliente recebem, por e-mail, o link para acessar a consulta. O link também estará disponível em sua agenda.</p>
                            </li>
                            
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> Como saberei quanto tenho a receber pelas minhas consultas e como será efetuado o pagamento?</a>
                                <p class="accordion-content">Após a aprovação de seu cadastro e criação de seu usuário na plataforma da Listen, você receberá um e-mail da Safe2Pay, que administra todas as movimentações da plataforma, para criar sua conta neste serviço. Assim, você poderá monitorar seus rendimentos com as consultas diretamente pela <a href="https://safe2pay.com.br" target="_blank">Safe2Pay</a> e transferi-los para sua conta bancária. Na plataforma Listen, você também pode visualizar a relação de consultas confirmadas e dos seus rendimentos.</p>
                            </li>
                            
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> Qual o valor da taxa paga pela utilização da plataforma Listen?</a>
                                <p class="accordion-content">A Taxa cobrada pela Listen Online pela utilização da Plataforma é de 20% (vinte por cento) sobre o valor bruto aplicado pelo Psicólogo, sendo esta taxa descontada automaticamente através da <a href="https://safe2pay.com.br" target="_blank">Safe2Pay</a> no momento da efetivação do pagamento pelo Usuário.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
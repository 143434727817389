<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" style="padding-bottom: 50px;">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/images/logo.png" alt="logo" /></a>
                    </div>
                    <p>Escutando você, gerando resultados.</p>

                    <!--div class="subscribe">
                        <h4>Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control" placeholder="seu e-mail" name="EMAIL" required autocomplete="off">
                            <button class="box-btn" type="submit">Inscreva-se</button>
                        </form>
                    </div-->

                    <ul class="social">
                        <li><a href="https://www.fb.com/listenonlineweb/" target="_blank"><i class='bx bxl-facebook' ></i></a></li>
                        <li><a href="https://www.instagram.com/listenonlineweb/" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Para Você</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/para-voce">O que é</a></li>
                        <li><a routerLink="/para-voce/como-funciona">Como Funciona</a></li>
                        <li><a routerLink="/pesquisa">Encontre um Profissional</a></li>
                        <li><a routerLink="/para-voce/cadastre-se">Cadastre-se</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content">
                    <h3>Para o Profissional</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/profissional">O que é</a></li>
                        <li><a routerLink="/profissional/como-funciona">Como Funciona</a></li>
                        <li><a routerLink="/profissional/inscreva-se">Inscreva-se</a></li>
                    </ul>


                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">A Listen</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/sobre">Sobre</a></li>
                        <li><a routerLink="/contato">Fale Conosco</a></li>
                        <li><a routerLink="/blog">Blog</a></li>
                    </ul>
                    <ul class="footer-list foot-social">
                        <li><a href="mailto:contato@listenonline.com.br"><i class="bx bxs-envelope"></i> contato@listenonline.com.br</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/sobre">Sobre</a></li>
                        <li><a routerLink="/contato">Fale Conosco</a></li>
                        <li><a routerLink="/blog">Blog</a></li>
                        <li><a (click)='callLogin()' target="_blank">Fazer Login</a></li>
                    </ul>
                </div>

                <div class="col-lg-6">
                    <p class="right">Copyright &copy; 2020 Listen. Todos os direitos reservados</p>
                </div>
            </div>
        </div>
    </div>
</footer>

<!--div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div-->
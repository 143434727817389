import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {

    location: any;
    navbarClass: any;

    constructor(
        private router: Router,
        location: Location
    ) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url;
                    if (this.location == '/home-three') {
                        this.navbarClass = 'navbar-area three';
                    } else {
                        this.navbarClass = 'navbar-area';
                    }
                }
            });
    }

    ngOnInit(): void {
    }

    callLogin() {
        window.location.href = environment.app_url + "pages/auth/login";
    }

}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AllServices } from 'src/app/services/all.service';
import { Options } from '@angular-slider/ngx-slider';
import { format, add } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-detalhes',
    templateUrl: './detalhes.component.html',
    styleUrls: ['./detalhes.component.scss']
})
export class DetalhesComponent implements OnInit {

    sending: false;
    professionals = [];
    days = [];

    today = new Date();

    selectedTime;
    selectedProfessional;

    searched = false;

    constructor(private allServices: AllServices,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private metaTagService: Meta,
        private _formBuilder: FormBuilder, private _matDialog: MatDialog) {

        let today = new Date();
        for (let i = 0; i < 7; i++) {
            this.days.push(add(today, { days: i }));
        }

        this.route.params.subscribe(params => {
            let slug = params.slug;

            this.allServices.searchUsers('slug=' + slug).subscribe(data => {
                this.professionals = [];
                let professional = data.users[0]
                if (!professional.profile_img_url) {
                    professional.profile_img_url = 'assets/images/profile.jpg';
                }
                this.updateProTimes(professional, this.days)
                this.professionals.push(professional);

                this.createTags(professional);
            })
        });
    }

    ngOnInit(): void {
        if (this.allServices.selectedProfessional) {
            this.professionals = [];
            this.professionals.push(this.allServices.selectedProfessional);
        }
    }

    markDay(time, professional) {
        // console.log(time);
        this.selectedTime = time;
        this.selectedProfessional = professional;
    }

    itsTime(time, professional) {
        if (this.selectedTime == time && this.selectedProfessional == professional) {
            return true;
        }
        return false;
    }

    callRegister() {
        let data = { professional_id: this.selectedProfessional.id, time: format(this.selectedTime, this.allServices.pattern) };
        this.router.navigate(['para-voce/cadastre-se', data]);
    }

    callLogin() {
        let data = { professional_id: this.selectedProfessional.id, time: format(this.selectedTime, this.allServices.pattern) };
        // console.log(data);
        window.location.href = environment.app_url + "pages/auth/login?data=" + btoa(JSON.stringify(data));
    }

    previousWeek(professional) {
        // console.log('previous');
        let week = add(professional.days[0], { weeks: -1 })
        this.getAvailibilities(professional, week);
    }

    nextWeek(professional) {
        // console.log('next');
        let week = add(professional.days[0], { weeks: 1 })
        this.getAvailibilities(professional, week)
    }

    getAvailibilities(professional, date) {
        const output = format(date, this.allServices.pattern)
        let days = this.getDays(date);

        this.allServices.getProfessionalAvailibilities(professional.professional_id, output).subscribe(data => {
            professional.availabilities = data.availabilities;
            this.updateProTimes(professional, days);
        });
    }

    getDays(start) {
        let days = [];
        for (let i = 0; i < 7; i++) {
            days.push(add(start, { days: i }));
        }
        return days;
    }

    checkDate(date) {
        if (add(date, { days: -1 }) >= this.today) {
            return true;
        } else {
            return false;
        }
    }

    updateProTimes(pro, days) {
        let pos = 0;
        let dates = [];
        pro.screenAvailableTimes = [];
        pro.days = days;
        for (let av of pro.availabilities) {
            const utcDate = zonedTimeToUtc(av.datetime, pro.timezone);
            // TODO - converter para o timezone do usuario
            if (utcDate.getDay() == days[pos].getDay()) {
                dates.push(utcDate);
            } else {
                pro.screenAvailableTimes.push({ date: days[pos], times: dates });
                while (pos < days.length) {
                    if (utcDate.getDay() == days[pos].getDay()) {
                        dates = [];
                        dates.push(utcDate);
                        break;
                    } else {
                        pos = pos + 1;
                    }
                }
            }
        }
        if (dates.length > 0) {
            pro.screenAvailableTimes.push({ date: days[pos], times: dates });
        }
    }

    createTags(professional) {
        this.titleService.setTitle(professional.name + ' | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({ name: 'description', content: 'Perfil de ' + professional.name });
        this.metaTagService.updateTag({ name: 'robots', content: 'index, follow' });
        this.metaTagService.updateTag({ name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' });
        this.metaTagService.updateTag({ name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' });

        this.metaTagService.updateTag({ property: 'og:locale', content: "pt_BR" });
        this.metaTagService.updateTag({ property: 'og:type', content: "article" });
        this.metaTagService.updateTag({ property: 'og:title', content: professional.name + ' | Listen - Estamos aqui para ouvir você' });
        this.metaTagService.updateTag({ property: 'og:description', content: '' });
        this.metaTagService.updateTag({ property: 'og:site_name', content: "Listen Online" });
        this.metaTagService.updateTag({ property: 'og:url', content: window.location.href });

        this.metaTagService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.metaTagService.updateTag({ name: 'twitter:title', content: professional.name + ' | Listen - Estamos aqui para ouvir você' });
        this.metaTagService.updateTag({ name: 'twitter:description', content: '' });
        this.metaTagService.updateTag({ name: 'twitter:url', content: window.location.href });
    }
}

import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AllServices} from 'src/app/services/all.service';
import {ValidateBrService} from 'angular-validate-br';
import {Title, Meta} from '@angular/platform-browser';

@Component({
    selector: 'app-contato',
    templateUrl: './contato.component.html',
    styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

    contactForm: FormGroup;

    sending = false;

    constructor(private allServices: AllServices,
        private _formBuilder: FormBuilder,
        private toast: ToastrService,
        private validateBrService: ValidateBrService,
        private router: Router,
        private titleService: Title,
        private metaTagService: Meta) {
            this.createTags();
    }

    ngOnInit(): void {
        this.contactForm = this.createContactForm();
    }
    
    createContactForm(): FormGroup {
        return this._formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phone_number: ['', [Validators.required]],
            msg_subject: ['', [Validators.required]],
            message: ['', [Validators.required]]
        });
    }
    
    sendContactForm() {
        if (this.contactForm.invalid) {
            this.toast.error('Formulário incompleto!', 'Por favor, preencha o formulário com todos os campos obrigatórios!')
        } else {
            this.sending = true;
            this.allServices.postContact(this.contactForm.value).subscribe(data => {
                this.toast.success('Mensagem enviada com sucesso!', 'Sucesso!');
                this.contactForm = this.createContactForm();
                this.sending = false;
            }, error => {
                this.toast.error(error.error.message, 'Erro!');
                this.sending = false;
            })
        }
    }
    
    createTags() {
        this.titleService.setTitle('Fale Conosco | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({name: 'description', content: 'Entre em contato. Queremos ouvir você!'});
        this.metaTagService.updateTag({name: 'robots', content: 'index, follow'});
        this.metaTagService.updateTag({name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});
        this.metaTagService.updateTag({name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});

        this.metaTagService.updateTag({property: 'og:locale', content: "pt_BR"});
        this.metaTagService.updateTag({property: 'og:type', content: "article"});
        this.metaTagService.updateTag({property: 'og:title', content: 'Fale Conosco | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({property: 'og:description', content: ''});
        this.metaTagService.updateTag({property: 'og:site_name', content: "Listen Online"});
        this.metaTagService.updateTag({property: 'og:url', content: window.location.href});

        this.metaTagService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.metaTagService.updateTag({name: 'twitter:title', content: 'Fale Conosco | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({name: 'twitter:description', content: ''});
        this.metaTagService.updateTag({name: 'twitter:url', content: window.location.href});
    }

}

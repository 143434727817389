<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-4 text-sm-left">
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/logo.png" alt="logo" /></a>
                </div>
                <a  (click)='callLogin()' class="box-btn btn-top" target="_blank">Fazer Login</a>
            </div>

            <div class="col-lg-8 col-sm-4 text-md-right text-right text-sm-center pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="mailto:contato@listenonline.com.br"><i class="bx bxs-envelope"></i> contato@listenonline.com.br</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4 text-right pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <li><a href="https://api.whatsapp.com/send?phone=5554992551811"><i class="bx bxl-whatsapp"></i></a></li>
                        <li><a href="https://www.fb.com/listenonlineweb/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/listenonlineweb/" target="_blank"> <i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/images/logo.png" alt="logo" /></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Para Você</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/para-voce/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">O que é</a></li>
                                <li class="nav-item"><a routerLink="/para-voce/como-funciona" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Como funciona</a></li>
                                <li class="nav-item"><a routerLink="/pesquisa" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Encontre um Profissional</a></li>
                                <li class="nav-item"><a routerLink="/para-voce/cadastre-se" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cadastre-se</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Para o Profissional</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/profissional/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">O que é</a></li>
                                <li class="nav-item"><a routerLink="/profissional/como-funciona" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Como funciona</a></li>
                                <li class="nav-item"><a routerLink="/profissional/inscreva-se" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Inscreva-se</a></li>
                            </ul>
                        </li>

                        <!--li class="nav-item">
                            <a routerLink="/como-funciona" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Como Funciona</a>
                        </li>
                        
                        <li class="nav-item">
                            <a routerLink="/sobre" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sobre a Listen</a>
                        </li-->

                        <li class="nav-item">
                            <a routerLink="/sobre" class="nav-link">Sobre</a>
                        </li>
                        
                        <li class="nav-item">
                            <a routerLink="/contato" class="nav-link">Fale Conosco</a>
                        </li>
                        
                        <li class="nav-item">
                            <a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a>
                        </li>

                        <!--li class="nav-item">
                            <a  (click)='callLogin()' class="nav-link" target="_blank">Login</a>
                        </li-->

                        <!--li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Solutions</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions</a></li>

                                <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Case Studies</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies</a></li>

                                <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Error 404</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" cl ass="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact </a></li-->
                    </ul>
                </div>

                <!--div class="nav-right">
                    <form>
                        <div class="input-group">
                            <input type="text" class="form-control search" placeholder="Search..." />
                        </div>

                        <button type="submit">
                            <i class="bx bx-search"></i>
                        </button>
                    </form>
                </div-->

                <div class="nav-btn">
                    <a  (click)='callLogin()' class="box-btn" target="_blank">Fazer Login</a>
                </div>
            </nav>
        </div>
    </div>
</div>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Inscreva-se</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/profissional">Para o Profissional </a></li>
                <li class="active">Inscreva-se</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-contact-area home-2-contact pb-100 pt-100 feature-area  ptb-100">
    <div class="container" *ngIf='register'>
        <div class="section-title">
            <span>Inscrição</span>
            <h2>Venha fazer parte da Listen</h2>
            <p>Para os profissionais, oferecemos um escritório virtual completo, com programação de agenda, contato com os clientes e controle financeiro. Tudo de maneira segura e automatizada, de acordo com as diretrizes e recomendações do CFP para atendimento online.</p>
            <p>Para participar como profissional parceiro, preencha o formulário abaixo.</p>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="contnet mb-5">
                    <div class="feature-tittle">
                        <span>Vantagens</span>
                        <h2>Todas as vantagens dos atendimento on-line em uma única ferramenta</h2>
                        <p>O atendimento on-line equivalente à psicoterapia presencial e, desde novembro de 2018, é permitido pelo Conselho Federal de Psicologia, sem limite de sessões. A Listen oferece todas as vantagens desta modalidade de atendimento, levando aos profissionais uma opção de ferramenta completa para um atendimento ágil e eficiente. As sessões duram 50 minutos, sendo realizadas após agendamento e pagamento prévios. Confira as vantagens abaixo e cadastre-se!</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Proporcionamos um consultório virtual completo para você, no qual você poderá atender seus clientes on-line.</li>
                        <li><i class="flaticon-correct"></i> Ao fazer login na plataforma, você terá acesso à sua programação de horários, controle de valores a receber, atualização de dados do seu perfil e muito mais.</li>
                        <li><i class="flaticon-correct"></i> Todas as consultas são realizadas pela nossa plataforma. Basta estar logado e selecionar a consulta em sua agenda.</li>
                        <li><i class="flaticon-correct"></i> Garantimos um atendimento dentro das normas exigidas pelo CFP - Conselho Federal de Psicologia.</li>
                        <li><i class="flaticon-correct"></i> Você pode oferecer diversas opções de pagamento aos seus pacientes.</li>
                        <li><i class="flaticon-correct"></i> O gerenciamento dos valores que você tem a receber será realizado através pela Safe2Pay (safe2pay.com.br), garantindo sigilo e segurança a seus dados e transações.</li>
                        <li><i class="flaticon-correct"></i> Seu cadastro será aprovado em até 24 horas. Depois de aprovado, você pode fazer o login na plataforma e começar a organizar sua agenda, ajustando seus horários da melhor forma para você e seus clientes.</li>
                        <li><i class="flaticon-correct"></i> Você pode atender seus clientes de qualquer lugar que tenha acesso à internet, através de um computador com câmera e microfone ou até de seu smartphone.</li>
                    </ul>

                    <p class="mt-5 mb-0">Já está cadastrado em nossa plataforma?</p>
                    <a (click)='callLogin()' class="box-btn" target="_blank">Fazer Login</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="content">
                    <!-- Formulario profissional -->
                    <div class='row' *ngIf='!pacient'>
                        <form id="professionalForm" [formGroup]="professionalForm">
                            <div class="row" >
                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input type="text" formControlName='name' name="name" id="name" class="form-control" required
                                               placeholder="Seu nome" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName='vat' name="vat" id="vat" class="form-control" required mask='000.000.000-00'
                                               placeholder="Seu CPF" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName='id_card_number' name="id_card_number" id="id_card_number" class="form-control" required
                                               placeholder="Seu RG" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName='phone' name="phone" id="phone" required class="form-control" placeholder="Seu telefone" mask='(00) 0000-0000||(00) 00000-0000'/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName='mobile' name="mobile" id="mobile" required class="form-control" placeholder="Seu celular" mask='(00) 00000-0000' />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <select name='gender' class='form-control' formControlName='gender'>
                                            <option value="" disabled selected>Selecione o sexo</option>
                                            <option value="M">Masculino</option>
                                            <option value="F">Feminino</option>
                                            <option value="O">Prefiro não informar</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName='birth_date' name="birth_date" id="birth_date" required class="form-control" placeholder="Data de Nascimento" mask='00/00/0000' [dropSpecialCharacters]="false"  />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input type="text" formControlName='registration_number' name="registration_number" id="registration_number" required class="form-control" placeholder="Seu número de registro no CRP" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <select name='crp_region_id' class='form-control' formControlName='crp_region_id'>
                                            <option value="" disabled selected>Selecione a região do registro</option>
                                            <option *ngFor='let region of crp_regions' value={{region.id}}>{{region.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input formControlName='email' type="email" name="email" id="email" class="form-control"
                                               required placeholder="Seu email" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input formControlName='password' type="password" name="password" id="password" class="form-control" required placeholder="Sua senha" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input formControlName='password_confirm' type="password" name="password_confirm" id="password_confirm" class="form-control" required placeholder="Confirme sua senha" />
                                    </div>
                                </div>

                                <div *ngIf="professionalForm.controls.password.hasError('strong')" class="message-box warning" style="margin: 1em;">
                                    <p class="accordion-content"> Insira uma senha forte: pelo menos 6 dígitos, contendo um número, uma letra maiúscula e uma letra minúscula. </p>
                                </div>
                                <div *ngIf="professionalForm.hasError('notMatch')" class="message-box warning"  style="margin: 1em;">
                                    <p class="accordion-content"> As senhas não estão iguais! </p>
                                </div>

                                <div class="col-lg-12 col-md-12 button-actions">
                                    <button type="submit" class="default-btn page-btn box-btn" style="display: inline-block;" [disabled]='sending' (click)='sendProfessionalForm()'>Cadastrar</button>
                                    <button (click)='callLogin()' class="box-btn border-btn ml-2" style="display: inline-block; border: 1px solid #de5915; color: #de5915;">Já cadastrado? Fazer Login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf='!register'>
        <div class="section-title">
            <span>Inscrição</span>
            <h2>Seu cadastro foi realizado com sucesso!</h2>
            <p>Como medida de segurança, você receberá um email para confirmar e ativar o seu acesso à plataforma Listen. Por favor, verifique sua caixa de e-mail e clique no link enviado.</p>
            <p><small>Caso não receba nenhum e-mail dentro de alguns minutos, é possível que seu provedor tenha enviado nossas mensagens para sua caixa de spam ou lixeira por engano. <br>
                    Se você tiver outros filtros ou regras de direcionamento em sua conta, eles podem ter enviado os e-mails da Listen para outra pasta. Não deixe de verificar isso também. <br>
                    Procure por e-mails enviados por <b>contato@listenonline.com.br</b></small></p>
        </div>
    </div>
</section>
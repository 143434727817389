<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Cadastre-se</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/para-voce">Para Você </a></li>
                <li class="active">Cadastre-se</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-contact-area home-2-contact pb-100 pt-100 feature-area  ptb-100">
    <div class="container" *ngIf='register'>
        <div class="section-title">
            <span>Cadastre-se</span>
            <h2>Aproveite a praticidade e segurança do atendimento psicológico on-line</h2>
            <p>Para participar como paciente, preencha o formulário abaixo.</p>
        </div>
        
        <div *ngIf="selectedProfessional" class="blog-details-area pb-70">
            <blockquote class="flaticon-quote" style="padding: 50px !important;"><p>Você está realizando um agendamento com <strong>{{selectedProfessionalName}}</strong> para o dia <strong>{{selectedTime | date:'dd/MM' }}</strong> às <strong>{{ selectedTime | date:'HH:mm'}}</strong>. Faça seu cadastro e tenha acesso à nossa área exclusiva, onde você poderá concluir o seu pagamento.</p></blockquote>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="contnet mb-5">
                    <div class="feature-tittle">
                        <span>Vantagens</span>
                        <h2>Estamos prontos para ouvir você</h2>
                        <p>Através da nossa plataforma você realiza consultas com os psicólogos de sua preferência, sem sair de casa e nos melhores horários para você. Nosso maior objetivo é atender às suas necessidades, oferecendo-lhe um serviço prático e seguro, que lhe garanta os melhores resultados.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Você pode fazer suas sessões de onde estiver. Basta um dispositivo com câmera e acesso à internet.</li>
                        <li><i class="flaticon-correct"></i> Após o cadastro, você terá acesso a uma área exclusiva, onde poderá programar seus horários e pagamentos.</li>
                        <li><i class="flaticon-correct"></i> Garantimos a total privacidade e segurança de seus dados.</li>
                        <li><i class="flaticon-correct"></i> Você pode escolher diferentes profissionais, conforme o foco de cada consulta.</li>
                        <li><i class="flaticon-correct"></i> Atendimento seguindo todas as normas do Concelho Federal de Psicologia (CFP).</li>
                        <li><i class="flaticon-correct"></i> Você pode optar por um plano para dar continuidade ao seu tratamento com o mesmo profissional.</li>
                        <li><i class="flaticon-correct"></i> Caso não puder realizar alguma consulta, você pode fazer o cancelamento, sem custo, em até 48 horas antes do horário agendado.</li>
                        <li><i class="flaticon-correct"></i> Você pode pagar suas consultas pelo cartão de crédito, débito ou boleto bancário.</li>
                    </ul>

                    <p class="mt-5 mb-0">Já está cadastrado em nossa plataforma?</p>
                    <a (click)='callLogin()' class="box-btn" target="_blank">Fazer Login</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="content">
                    <!-- Formulario profissional -->
                    <div class='row' *ngIf='!pacient'>
                        <form id="professionalForm" [formGroup]="professionalForm">
                            <div class="row" >
                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input type="text" formControlName='name' name="name" id="name" class="form-control" required
                                               placeholder="Seu nome" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName='vat' name="vat" id="vat" class="form-control" required mask='000.000.000-00'
                                               placeholder="Seu CPF" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName='id_card_number' name="id_card_number" id="id_card_number" class="form-control" required
                                               placeholder="Seu RG" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName='phone' name="phone" id="phone" required class="form-control" placeholder="Seu telefone" mask='(00) 0000-0000||(00) 00000-0000'/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName='mobile' name="mobile" id="mobile" required class="form-control" placeholder="Seu celular" mask='(00) 00000-0000' />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <select name='gender' class='form-control' formControlName='gender'>
                                            <option value="" disabled selected>Selecione o sexo</option>
                                            <option value="M">Masculino</option>
                                            <option value="F">Feminino</option>
                                            <option value="O">Prefiro não informar</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName='birth_date' name="birth_date" id="birth_date" required class="form-control" placeholder="Data de Nascimento" mask='00/00/0000' [dropSpecialCharacters]="false"  />
                                    </div>
                                </div>


                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input formControlName='email' type="email" name="email" id="email" class="form-control"
                                               required placeholder="Seu email" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input formControlName='password' type="password" name="password" id="password" class="form-control" required placeholder="Sua senha" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input formControlName='password_confirm' type="password" name="password_confirm" id="password_confirm" class="form-control" required placeholder="Confirme sua senha" />
                                    </div>
                                </div>

                                <div *ngIf="professionalForm.controls.password.hasError('strong')" class="message-box warning" style="margin: 1em;">
                                    <p class="accordion-content"> Insira uma senha forte: pelo menos 6 dígitos, contendo um número, uma letra maiúscula e uma letra minúscula. </p>
                                </div>
                                <div *ngIf="professionalForm.hasError('notMatch')" class="message-box warning"  style="margin: 1em;">
                                    <p class="accordion-content"> As senhas não estão iguais! </p>
                                </div>
                                <div *ngIf="professionalForm.hasError('minimumAge')" class="message-box warning" style="margin: 1em;">
                                    <p class="accordion-content"> A idade mínima é de 18 anos para o cadastro no site. </p>
                                </div>

                                <div class="col-lg-12 col-md-12 button-actions">
                                    <button type="submit" class="default-btn page-btn box-btn" style="display: inline-block;" [disabled]='sending' (click)='sendProfessionalForm()'>Cadastrar</button>
                                    <button (click)='callLogin()' class="box-btn border-btn ml-2" style="display: inline-block; border: 1px solid #de5915; color: #de5915;">Já cadastrado? Fazer Login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf='!register'>
        <div class="section-title">
            <span>Inscrição</span>
            <h2>Seu cadastro foi realizado com sucesso!</h2>
            <p>Como medida de segurança, você receberá um email para confirmar e ativar o seu acesso à plataforma Listen. Por favor, verifique sua caixa de e-mail e clique no link de atiavação enviado.</p>
            <p><small>Caso não receba nenhum e-mail dentro de alguns minutos, é possível que seu provedor tenha enviado nossas mensagens para sua caixa de spam ou lixeira por engano. <br>
                    Se você tiver outros filtros ou regras de direcionamento em sua conta, eles podem ter enviado os e-mails da Listen para outra pasta. Não deixe de verificar isso também. <br>
                    Procure por e-mails enviados por <b>contato@listenonline.com.br</b></small></p>
        </div>
    </div>
</section>
<section class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Estamos aqui para ouvir você</h1>
                            <p>Nosso maior objetivo é atender às suas necessidades, oferecendo-lhe opções de atendimento com profissionais especializados, que lhe oferecerão soluções para gerar os melhores resultados pessoais e profissionais.</p>
                            <div class="banner-btn">
                                <a href="/para-voce/como-funciona" class="box-btn border-btn">Como funciona</a>
                                <a href="/pesquisa" class="box-btn"><i class="fa fa-search"></i> Encontre um Profissional</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                            <img src="assets/images/home-bg-1-img.png" alt="banner-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>

<!-- section class="home-contact-area home-2-contact pb-100 pt-100 feature-area  ptb-100 bg-color">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Aguarde</span>
                        <h2>Estamos em fase de lançamento!</h2>
                        <p>A Listen está em fase de seleção e de credenciamento de profissionais para lhe atender. Em breve estaremos disponibilizando completamente os nossos serviços.</p>
                        <p>Fique ligado!</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <div class='row'>
                        <p>Deixe seu contato e seja o primeiro a saber quando nossos serviços estiverem disponíveis:</p>
                        <form id="subscribeForm" [formGroup]="subscribeForm">
                            <div class="row" >
                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input type="text" formControlName='name' name="name" id="name" class="form-control" required
                                               placeholder="Seu nome" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input formControlName='email' type="email" name="email" id="email" class="form-control"
                                               required placeholder="Seu email" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn page-btn box-btn" style="display: inline-block;" [disabled]='sending' (click)='sendSubscribeForm()'>Me avise!</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Listen Online</span>
            <h2>Uma plataforma completa</h2>
            <p>Através da nossa plataforma, você realiza consultas com profissionais de diferentes especializações, sem sair de casa e nos horários de sua preferência.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Conectividade</h3>
                        <p>Você pode realizar consultas com psicólogos pela internet, de onde estiver.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Flexibilidade</h3>
                        <p>Você mesmo programa seus horários conforme sua disponibilidade e a agenda do profissional, adaptando suas consultas ao seu ritmo de atividade.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Praticidade</h3>
                        <p>Você paga pelos serviços conforme utilizá-los, tudo de forma segura através da plataforma.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Controle</h3>
                        <p>Você mesmo controla suas consultas, horários e valores pagos, podendo adaptar sua rotina de uso da plataforma conforme suas possibilidades.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Privacidade</h3>
                        <p>Fique tranquilo conversando com um profissional por um canal de vídeo exclusivo e totalmente seguro.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Conteúdo</h3>
                        <p>Conteúdos desenvolvidos pelos nossos profissionais para que você fique sempre por dentro das novidades sobre os segmentos em nossa plataforma.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
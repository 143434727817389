import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';

import { ParaVoceComponent } from './components/pages/para-voce/para-voce.component';
import { ParaVoceComoFuncionaComponent } from './components/pages/para-voce-como-funciona/para-voce-como-funciona.component';
import { SobreComponent } from './components/pages/sobre/sobre.component';

import { ProfissionalComponent } from './components/pages/profissional/profissional.component';
import { ProfissionalComoFuncionaComponent } from './components/pages/profissional-como-funciona/profissional-como-funciona.component';
import { ProfissionalInscrevaSeComponent } from './components/pages/profissional-inscreva-se/profissional-inscreva-se.component';

import { ConfirmarCadastroComponent } from './components/pages/confirmar-cadastro/confirmar-cadastro.component';
import { ContatoComponent } from './components/pages/contato/contato.component';

import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { SolutionsComponent } from './components/pages/solutions/solutions.component';
import { SolutionsDetailsComponent } from './components/pages/solutions-details/solutions-details.component';
import { CaseStudiesComponent } from './components/pages/case-studies/case-studies.component';
import { CaseStudiesDetailsComponent } from './components/pages/case-studies-details/case-studies-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogDetails08Component } from './components/pages/blog-details-08/blog-details-08.component';
import { BlogDetails07Component } from './components/pages/blog-details-07/blog-details-07.component';
import { BlogDetails06Component } from './components/pages/blog-details-06/blog-details-06.component';
import { BlogDetails05Component } from './components/pages/blog-details-05/blog-details-05.component';
import { BlogDetails04Component } from './components/pages/blog-details-04/blog-details-04.component';
import { BlogDetails03Component } from './components/pages/blog-details-03/blog-details-03.component';
import { BlogDetails02Component } from './components/pages/blog-details-02/blog-details-02.component';
import { BlogDetails01Component } from './components/pages/blog-details-01/blog-details-01.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { PesquisaComponent } from './components/pages/pesquisa/pesquisa.component';
import { DetalhesComponent } from './components/pages/detalhes/detalhes.component';
import { ParaVoceCadastreSeComponent } from './components/pages/para-voce-cadastre-se/para-voce-cadastre-se.component';

const routes: Routes = [
    { path: '', component: HomeTwoComponent },

    { path: 'para-voce', component: ParaVoceComponent },
    { path: 'para-voce/como-funciona', component: ParaVoceComoFuncionaComponent },
    { path: 'para-voce/cadastre-se', component: ParaVoceCadastreSeComponent },

    { path: 'profissional', component: ProfissionalComponent },
    { path: 'profissional/como-funciona', component: ProfissionalComoFuncionaComponent },
    { path: 'profissional/inscreva-se', component: ProfissionalInscrevaSeComponent },

    { path: 'confirmar-cadastro/:id', component: ConfirmarCadastroComponent },

    { path: 'sobre', component: SobreComponent },
    { path: 'contato', component: ContatoComponent },
    { path: 'pesquisa', component: PesquisaComponent },
    { path: 'detalhes', component: DetalhesComponent },
    { path: 'profissional/:slug', component: DetalhesComponent },

    { path: 'blog', component: BlogComponent },
    { path: 'blog/o-que-e-anamnese', component: BlogDetails08Component },
    { path: 'blog/tempestade-emocional', component: BlogDetails07Component },
    { path: 'blog/o-trabalho-esta-te-matando', component: BlogDetails06Component },
    { path: 'blog/atendimento-online-e-permitido', component: BlogDetails05Component },
    { path: 'blog/o-conto-da-bela-adormecida', component: BlogDetails04Component },
    { path: 'blog/saude-mental', component: BlogDetails03Component },
    { path: 'blog/qual-e-o-papel-do-psicologo', component: BlogDetails02Component },
    { path: 'blog/a-psicologia-e-importante', component: BlogDetails01Component },

    //    {path: 'home-one', component: HomeOneComponent},
    //    {path: 'home-two', component: HomeTwoComponent},
    //    {path: 'home-three', component: HomeThreeComponent},
    //    {path: 'solutions', component: SolutionsComponent},
    //    {path: 'solutions-details', component: SolutionsDetailsComponent},
    //    {path: 'case-studies', component: CaseStudiesComponent},
    //    {path: 'case-studies-details', component: CaseStudiesDetailsComponent},
    //    {path: 'blog', component: BlogComponent},
    //    {path: 'blog-details', component: BlogDetailsComponent},
    //    {path: 'team', component: TeamComponent},
    //    {path: 'pricing', component: PricingComponent},
    //    {path: 'gallery', component: GalleryComponent},
    //    {path: 'testimonials', component: TestimonialsComponent},
    //    {path: 'sign-up', component: SignUpComponent},
    //    {path: 'sign-in', component: SignInComponent},
    //    {path: 'error', component: ErrorComponent},
    //    {path: 'terms-condition', component: TermsConditionsComponent},
    //    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    { path: '**', component: ErrorComponent },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        useHash: false,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
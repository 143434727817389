<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Tempestade emocional</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/blog">Blog </a></li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100 pt-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <!--div class="article-image">
                        <img src="assets/images/blog-details/blog-details.jpg" alt="">
                    </div-->

                    <div class="article-content">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="single-testimonials">
                                    <div class="testimonials-head">
                                        <div class="row align-items-center">
                                            <div class="col-lg-3 col-md-3 col-5">
                                                <div class="testimonials-img">
                                                    <img src="https://www.listenonline.com.br/server/assets/img/users/45feb41001c7191d860480f085d35a30.png" alt="">
                                                </div>
                                            </div>

                                            <div class="col-lg-9 col-md-9 col-7">
                                                <div class="content">
                                                    <span>Por:</span>
                                                    <h4>Thais Michelle Kohler Barbosa</h4>
                                                    <span>Psicóloga - CRP 14/073572</span>
                                                    <p><br><a href="https://www.listenonline.com.br/profissional/thais-michelle-kohler-barbosa" class="box-btn">Conheça</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>

                        <h3 style="margin-top: 0px">Tempestade emocional: o que fazer com a "birra infantil"?</h3>

                        <div class="entry-meta mb-20">
                            <ul>
                                <li><span>Publicado em:</span>&nbsp;18 de Janeiro de 2020</li>
                                <!--li><span>Por:</span>&nbsp;<a routerLink="/sobre">Equipe Listen</a></li-->
                            </ul>
                        </div>

                        <p>Estes comportamentos sempre querem nos dizer algo. Vamos entender melhor? </p>
                        <p>Os pais, em geral, não conseguem avaliar este momento e só querem que a criança pare, mas o que precisamos entender é que as crianças estão em fase de desenvolvimento e cada fase nos traz algo novo e desafiador. Cabe aos pais entenderem
                            melhor este momento para a criança conseguir passar pelas tempestades emocionais de forma segura e acolhedora. </p>

                        <h4>Observe a criança</h4>
                        <p>Pense que conhecer seu filho pode ser algo muito prazeroso e que pode lhe trazer muitos ganhos. Quando observamos a criança percebemos com maior facilidade o que pode estar causando a “birra”. Comece observando o que ela gosta,
                            o que não gosta e como reage a diferentes situações. Muitas vezes essa tempestade emocional pode vir de uma alteração na rotina da criança ou da família, pode ser devida a um cansaço ou algo diferente no ambiente - seja na
                            alimentação, no espaço, nos ruídos ou até mesmo nas mudanças de hábitos. Tudo isso pode ser algo novo para os pequenos e eles podem não estar sabendo lidar com isso.</p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art7_01.jpg" alt="">
                                <figcaption style="text-align: center;"><small>A famosa "birra" é uma forma da criança expressar sua dificuldade de reagir às situações que ela vive.<br>Créditos: Pexels</small></figcaption>
                            </figure>
                        </div>

                        <h4>O que é Tempestade Emocional?</h4>
                        <p>Vale lembrar que é natural e todas as crianças passam pelas "birras"; umas mais, outras menos. O que vai diferenciar cada situação é a forma como a família lida com essas emoções. Pense que seu filho está aprendendo a se comunicar
                            e ainda não sabe ser assertivo e vocês enquanto pais precisam observar e entender o que eles estão querendo dizer. A criança que está passando pela "birra" pode estar tentando dizer algo. Muitas vezes, na birra, a criança chora
                            e grita como tentativa de se expressar. </p>

                        <h4>O que fazer no momento da birra? Que tal não gritar e surtar junto? </h4>
                        <p>Tente ser o ponto de escuta, um ato de acolhimento. Abaixar-se até a altura da criança, conversar e ensinar... tudo isso valida seus sentimentos e assim é possível redirecionar o foco, inserindo outra solução para aquele estado
                            de descontrole, uma atividade, um colinho ou descanso. </p>
                        <p>As crianças aprendem observando; então, elas aprendem com vocês a reagir ao mundo e às situações que vivenciam. Julgar os pais que passam por isso não ajuda em nada.</p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art7_02.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Tente ser o ponto de escuta, um ato de acolhimento para a criança.<br>Créditos: Pexels</small></figcaption>
                            </figure>
                        </div>
                        <p>A terapia vai lhe ajudar a encontrar uma forma de lidar com estas situações e aliviar suas tensões diárias provenientes de conflitos ou situações ruins, além de devolver sua confiança e ajudar a ser mais assertivo com seus filhos.</p>

                        <figure style="float: left; width: 300px; margin-right: 30px; margin-bottom: 10px;">
                            <img src="assets/images/blog/art7_03.png" alt="">
                        </figure>
                        <p>Nós, da Listen, estamos aqui para ouvir você, para buscar a compreensão de sua história, lhe ajudar a se descobrir, a compreender suas dificuldades e identificar como você se relaciona com seu mundo interior e exterior.</p>
                        <p>Agende uma consulta com nossos profissionais.</p>
                        <p><a routerLink="/pesquisa" class="box-btn mt-3" style="width: 100%">Encontre um Profissional</a></p>

                        <p class="mt-5"><small><b>REFERÊNCIAS:</b></small></p>
                        <p class="reference">
                            Diferentes concepções da infância e adolescência: a importância da historicidade para sua construção; disponível em: http://pepsic.bvsalud.org/scielo.php?script=sci_abstract&pid=S1808-42812007000100013.
                        </p>
                        <p class="reference">
                            As birras das crianças: o papel do educador e dos encarregados de educação; disponível em: https://repositorio.ipl.pt/bitstream/10400.21/4112/1/As%20birras%20das%20crian%c3%a7as.pdf
                        </p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a>Compartilhe</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/intent/tweet?url=https://www.listenonline.com.br/blog/{{ article_slug }}&text=" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.listenonline.com.br/blog/{{ article_slug }}&title=&summary=&source=" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="https://pinterest.com/pin/create/button/?url=https://www.listenonline.com.br/blog/{{ article_slug }}&media=&description=" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                <li><a href="mailto:info@example.com?&subject=&body=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bx-envelope'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog"><i class='bx bx-left-arrow-alt'></i> Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <app-blog-outros-artigos [article_id]="article_id"></app-blog-outros-artigos>
                    <app-blog-tags></app-blog-tags>
                    <app-blog-cta></app-blog-cta>
                </aside>
            </div>
        </div>
    </div>
</section>
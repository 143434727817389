<section class="widget widget_tag_cloud" style="margin-top: 35px;">
    <h3 class="widget-title">Tags</h3>
    <div class="post-wrap">
        <div class="tagcloud">
            <a>abordagem</a>
            <a>analise</a>
            <a>analítica</a>
            <a>ansiedade</a>
            <a>atendimento</a>
            <a>behaviorismo</a>
            <a>clínica</a>
            <a>cognitivo</a>
            <a>comportamental</a>
            <a>fenomenologia</a>
            <a>gestalt</a>
            <a>humanista</a>
            <a>online</a>
            <a>personalidade</a>
            <a>positiva</a>
            <a>psicanálise</a>
            <a>psicodrama</a>
            <a>psicologia</a>
            <a>psicólogo</a>
            <a>psicoterapia</a>
            <a>saúde</a>
            <a>terapia</a>
            <a>terapeuta</a>
            <a>terapêutica</a>
            <a>tratamento</a>
        </div>
    </div>
</section>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>O conto da Bela Adormecida</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/blog">Blog </a></li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100 pt-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <!--div class="article-image">
                        <img src="assets/images/blog-details/blog-details.jpg" alt="">
                    </div-->

                    <div class="article-content">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="single-testimonials">
                                    <div class="testimonials-head">
                                        <div class="row align-items-center">
                                            <div class="col-lg-3 col-md-3 col-5">
                                                <div class="testimonials-img">
                                                    <img src="https://www.listenonline.com.br/server/assets/img/users/8ddf2f5de41b9b03bffe5708fe3bd473.png" alt="">
                                                </div>
                                            </div>

                                            <div class="col-lg-9 col-md-9 col-7">
                                                <div class="content">
                                                    <span>Por:</span>
                                                    <h4>Renara Raiana Fagundes Novais</h4>
                                                    <span>Psicóloga</span>
                                                    <p><br><a href="https://www.listenonline.com.br/profissional/renara-raiana-fagundes-novais" class="box-btn">Conheça</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>

                        <h3 style="margin-top: 0px">O conto da Bela Adormecida: história infantil ou uma boa metáfora sobre Violência Contra a Mulher?</h3>

                        <div class="entry-meta mb-20">
                            <ul>
                                <li><span>Publicado em:</span>&nbsp;13 de Janeiro de 2020</li>
                                <!--li><span>Por:</span>&nbsp;<a href="https://www.listenonline.com.br/profissional/renara-raiana-fagundes-novais">Renara Novais</a></li-->
                            </ul>
                        </div>

                        <figure style="float: left; width: 220px; margin-right: 30px; margin-bottom: 10px;">
                            <img src="assets/images/blog/art4_02.png" alt="">
                            <figcaption style="text-align: center;"><small>Créditos: Blog Mistérios Fantásticos</small></figcaption>
                        </figure>
                        <p>Bela Adormecida é um conto que tem várias versões, desde a famosa história criada pelos irmãos Grimm aos populares filmes da Disney. Em todas as versões a história tem a mesma base: a bela filha de um rei, depois de certa idade,
                            cai em um sono profundo ao se ferir com uma máquina de fiar… Mas, a história original não é tão fofinha quanto à mostrada nos filmes.</p>
                        <p>Na versão escrita em 1634 por Giambattista Basile encontramos muitos aspectos sociais e morais do século XV, sobretudo o domínio dos homens nas decisões sociais e com relação ao corpo feminino. Contudo, guardados os traços que
                            acentuam a época, essa história poderia facilmente se encaixar em vários relatos de mulheres que passam por situações de abuso.</p>
                        <figure style="float: left; width: 220px; margin-right: 30px; margin-bottom: 10px;">
                            <img src="assets/images/blog/art4_03.png" alt="">
                            <figcaption style="text-align: center;"><small>Ao longo da história humana muitos homens tomaram decisões sobre a mulher e seu corpo sem ao menos consultá-las.<br>Créditos: Blog Mistérios Fantásticos</small></figcaption>
                        </figure>
                        <p>Vamos brevemente contextualizar a história apresentada por Giambattista Basile para conversarmos sobre alguns pontos-chave, certo? De início temos um pai que não consegue lidar com a perda de sua única filha e, sendo incapaz de
                            enterrá-la, decide que o melhor para a princesa é ficar isolada em um castelo.</p>
                        <p>À primeira vista parece uma decisão extrema e até absurda demais para ser considerada, mas nos faz refletir que ao longo da história humana muitos homens tomaram decisões sobre a mulher e seu corpo sem ao menos consultá-las, e
                            assim como na história da Bela Adormecida, tiveram que lidar com os impactos e consequências de decisões arbitrárias.</p>
                        <p>Retornando à história, eis que anos mais tarde um monarca encontra o castelo e a mulher adormecida. Encantado com o corpo inconsciente da jovem princesa, usa-o considerando apenas os seus próprios desejos, sem consentimento. O
                            rei, depois do ato, retorna à sua vida, mas, meses depois, a princesa - até então adormecida -, desperta para dar à luz duas crianças.</p>
                        <p>Nesse sentido podemos perceber que a noção de amor romântico (introduzida nos filmes) não existe aqui. O que permanece até hoje, com poucas mudanças, é o abuso sexual e moral de mulheres.</p>
                        <figure style="float: left; width: 220px; margin-right: 30px; margin-bottom: 10px;">
                            <img src="assets/images/blog/art4_04.png" alt="">
                            <figcaption style="text-align: center;"><small>O estupro vitima milhares de mulheres de todas as idades todos os dias, trazendo consequências severas para essas mulheres.<br>Créditos: Blog Mistérios Fantásticos</small></figcaption>
                        </figure>
                        <p>Quantas “Belas Adormecidas” podemos encontrar por aí? Quantas mulheres calam-se diante de abuso com medo de ser julgada?</p>
                        <p>Conforme o 9º Anuário Brasileiro de Segurança Pública (Fórum Brasileiro de Segurança Pública, 2015), em 2014 foram registrados 47.643 casos de estupro em todo o Brasil. O dado representa um estupro a cada 11 minutos e esse número
                            cresce exponencialmente.</p>
                        <p>Ou seja, o estupro vitima milhares de mulheres de todas as idades todos os dias, trazendo consequências severas para essas mulheres e não apenas no aspecto físico (gravidez, infecções sexualmente transmissíveis e infecções no aparelho
                            reprodutivo), mas também emocionalmente e socialmente, pois podem desenvolver distúrbios na esfera da sexualidade, apresentando ainda maior vulnerabilidade para sintomas psiquiátricos, principalmente depressão, pânico, somatização,
                            tentativa de suicídio, abuso e dependência de substâncias psicoativas, entre outros fatores. </p>
                        <p>A antropóloga e pesquisadora Debora Diniz publicou no Jornal O Estado de São Paulo o seguinte:</p>
                        <blockquote class="flaticon-quote">
                            <p>"O estupro ofende as mulheres, não só no corpo possuído pelo prazer e ímpeto de tortura do agressor, mas principalmente porque nos aliena da única existência possível: a do próprio corpo."</p>
                        </blockquote>
                        <p>Questões como essas causam extremo sofrimento para as mulheres. A psicoterapia pode ser uma potente ferramenta de escuta em momentos tão dolorosos, principalmente quando vivemos na cultura que tudo é “mimimi”. Em casos de violência,
                            procure ajuda ou ajude alguém a buscá-la, pois a omissão também machuca.</p>
                        <h5>Bela Adormecida: conto de fadas ou realidade? Eis a questão!</h5>

                        <p class="mt-5"><small><b>REFERÊNCIAS:</b></small></p>
                        <p class="reference">
                            BASILE, Giambattista. Sol, Lua e Tália. Disponível em: https://volobuef.tripod.com/op_basile_sol_lua_talia_kvolobuef.pdf. Acesso em: 19 de outubro de 2020.</p>
                        <p class="reference">
                            BRASIL. 9º Anuário Brasileiro de Segurança Pública (Fórum Brasileiro de Segurança Pública, 2015), 2016. Disponível em: https://forumseguranca.org.br/storage/9_anuario_2015.retificado_.pdf. Acesso em 06 de janeiro de 2021.</p>
                        <p class="reference">
                            VIOLÊNCIA SEXUAL. Disponível em: https://dossies.agenciapatriciagalvao.org.br/violencia/violencias/violencia-sexual/ Acesso em: 05 de janeiro de 2021.
                        </p>

                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a>Compartilhe</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/intent/tweet?url=https://www.listenonline.com.br/blog/{{ article_slug }}&text=" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.listenonline.com.br/blog/{{ article_slug }}&title=&summary=&source=" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="https://pinterest.com/pin/create/button/?url=https://www.listenonline.com.br/blog/{{ article_slug }}&media=&description=" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                <li><a href="mailto:info@example.com?&subject=&body=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bx-envelope'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog"><i class='bx bx-left-arrow-alt'></i> Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <app-blog-outros-artigos [article_id]="article_id"></app-blog-outros-artigos>
                    <app-blog-tags></app-blog-tags>
                    <app-blog-cta></app-blog-cta>
                </aside>
            </div>
        </div>
    </div>
</section>
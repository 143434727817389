<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-primary m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Confirmar agendamento</span>
            <div class="align-right">
                <button mat-icon-button  class='toolbar-button' (click)="matDialogRef.close(false)" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="content" fusePerfectScrollbar>
        <div class="blog-details-area">
            <blockquote class="flaticon-quote"><p>Você está realizando um agendamento com <strong>{{ professional.name }}</strong> para o dia <strong>{{time | date:'dd/MM' }}</strong> às <strong>{{ time | date:'HH:mm'}}</strong>. 
                    Para confirmar, você precisa realizar o pagamento acessando a àrea restrita da plataforma Listen Online.</p></blockquote>
        </div>
        Se você já é cadastrado, clique no botão Fazer Login. Caso ainda não tenha se cadastrado, clique no botão Cadastre-se: é simples e rápido.
    </div>
    <div mat-dialog-actions class="m-0 p-12 mat-dialog-actions" fxLayout="row" fxLayoutAlign="start center">
        <button mat-raised-button 
                (click)="matDialogRef.close('register')"
                class="save-button"
                aria-label="SAVE" class=" add-todo-button mat-raised-button mat-button-base ml-3">
                CADASTRAR-SE
    </button>
    <button mat-raised-button 
            (click)="matDialogRef.close('login')"
            class="save-button"
            aria-label="SAVE" class=" add-todo-button mat-raised-button mat-button-base">
            FAZER LOGIN
</button>
<button class='cancel-button mat-raised-button mat-button-base' mat-raised-button (click)="matDialogRef.close(false)">FECHAR</button>
</div>        

</div>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>O trabalho está te matando?</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/blog">Blog </a></li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100 pt-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <!--div class="article-image">
                        <img src="assets/images/blog-details/blog-details.jpg" alt="">
                    </div-->

                    <div class="article-content">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="single-testimonials">
                                    <div class="testimonials-head">
                                        <div class="row align-items-center">
                                            <div class="col-lg-3 col-md-3 col-5">
                                                <div class="testimonials-img">
                                                    <img src="https://www.listenonline.com.br/server/assets/img/users/94515832324350712e2cb8171ae04576.png" alt="">
                                                </div>
                                            </div>

                                            <div class="col-lg-9 col-md-9 col-7">
                                                <div class="content">
                                                    <span>Por:</span>
                                                    <h4>Alana Neves Pinto</h4>
                                                    <span>Psicóloga - CRP 03/17487</span>
                                                    <p><br><a href="https://www.listenonline.com.br/profissional/alana-neves-pinto" class="box-btn">Conheça</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>

                        <h3 style="margin-top: 0px">O trabalho está te matando?</h3>

                        <div class="entry-meta mb-20">
                            <ul>
                                <li><span>Publicado em:</span>&nbsp;15 de Janeiro de 2020</li>
                                <!--li><span>Por:</span>&nbsp;<a routerLink="/sobre">Equipe Listen</a></li-->
                            </ul>
                        </div>

                        <p>Quem nunca ouviu aquela frase "O trabalho dignifica o homem?" Mas, afinal, o que é o trabalho?</p>
                        <p>O trabalho é uma categoria fundamental à existência humana! É através da atividade do trabalho que o indivíduo transforma os recursos disponíveis na natureza em produtos que atendam às suas necessidades materiais (Marx, 1983).
                            O indivíduo estabelece uma relação psíquica com o mundo do trabalho assim o mesmo se constitui enquanto ser social atendendo suas necessidades de sobrevivência.</p>
                        <h4>Por que o trabalho dignifica o homem?</h4>
                        <p>A atividade laboral é essencial para a saúde psíquica. Ela denota uma rede de significados onde os aspectos psíquicos e físicos estão fortemente relacionados e influenciam diretamente o desenvolvimento psicossocial do indivíduo.
                            O ambiente de trabalho é um lugar que proporciona ao trabalhador inúmeras experiências, permitindo que ele se desenvolva material e culturalmente.
                        </p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art6_01.jpg" alt="">
                                <figcaption style="text-align: center;"><small>O ambiente de trabalho proporciona inúmeras experiências.<br>Créditos: Pexels</small></figcaption>
                            </figure>
                        </div>
                        <p>Devido à necessidade de subsídios para sua sobrevivência, o trabalhador por vezes ignora as reais condições de trabalho que só visam à obtenção de lucro, condições essas que não possuem como centro o bem-estar psíquico e físico
                            dos trabalhadores. O controle coercitivo no trabalho não está voltado somente para o corpo, ele atinge o psíquico, ou seja, a autonomia e a criatividade do trabalhador. </p>

                        <h4>Quando o trabalho gera sofrimento?</h4>
                        <p>O trabalho torna-se fonte de sofrimento quando suas formas de organização não proporcionam o respeito e a valorização do trabalhador, isso incide porque na maioria das vezes o método de trabalho não proporciona espaços para que
                            o indivíduo expresse sua subjetividade. Por exemplo:</p>
                        <p><em> 	O sujeito não tem a liberdade de tomar decisões diante de um evento, pois se o resultado dessa atitude for negativo será punido por isso.</em></p>
                        <p>Essa rigidez organizacional não possibilita ao trabalhador o desenvolvimento de sua criatividade; pelo contrário, ele bloqueia o desejo e leva o trabalhador a adoecer.</p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art6_02.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Quando o indivíduo não pode expressar sua subjetividade, o trabalho pode proporcionar sofrimento.<br>Créditos: Pexels</small></figcaption>
                            </figure>
                        </div>
                        <p>Os trabalhadores precisam de ambientes de trabalho sem pressões e exigências rígidas repetitivas que geram frustrações, eles precisam ver possibilidades de transformações no trabalho. Qualquer ambiente diferente dessa realidade
                            é adoecedor.</p>
                        <p>O desejo do trabalhador entra em conflito com a hierarquia da instituição, pois as ordens dos superiores prevalecem sobre os desejos dos funcionários, tornando-os submissos e controlados. Segundo Dejours (1999), o sofrimento no
                            trabalho entre suas causas: </p>
                        <ul class="article-ul mb-3">
                            <li>A dificuldade do relacionamento interpessoal</li>
                            <li>Situações de injustiças</li>
                            <li>Afinco não reconhecido</li>
                        </ul>

                        <h4>A importância da psicoterapia</h4>
                        <p>Para auxiliar o sujeito a encontrar a melhor forma de lidar com essas questões existe a psicoterapia, que consiste em promover a autonomia do sujeito, proporcionando o auxílio na construção de estratégias defensivas para mediar
                            o sofrimento e ressignificá-lo. O apoio profissional no autoconhecimento torna-se extremamente valioso, principalmente com todo este atual cenário que estamos vivendo de pandemia, que exige isolamento social.</p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art6_03.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Psicoterapia promove a autonomia do sujeito.<br>Créditos: Pexels</small></figcaption>
                            </figure>
                        </div>
                        <p>Nós da Listen estamos aqui para ouvir você, para buscar a compreensão de sua história, lhe ajudar a se descobrir, a compreender suas dificuldades e promover a autonomia do sujeito, proporcionando o auxílio na construção de estratégias
                            defensivas para mediar o sofrimento e ressignificá-lo.</p>
                        <figure style="float: left; width: 300px; margin-right: 30px; margin-bottom: 10px;">
                            <img src="assets/images/blog/art6_04.jpg" alt="">
                        </figure>
                        <p>A terapia vai lhe proporcionar o encontro com um propósito de vida, aliviar sus tensões diárias provenientes de conflitos ou situações ruins, além de devolver sua confiança e ajudar em novos desafios. </p>
                        <p>E você, está sofrendo no trabalho?</p>
                        <p>Cuide de sua saúde mental, busque um psicólogo!</p>
                        <p>E lembre-se: você não está sozinha(o)!</p>
                        <p>Agende uma consulta com nossos profissionais.</p>
                        <p><a routerLink="/pesquisa" class="box-btn mt-3" style="width: 100%">Encontre um Profissional</a></p>

                        <p class="mt-5"><small><b>REFERÊNCIAS:</b></small></p>
                        <p class="reference">
                            Psicopalestra (Maria Silvia Navarro Psicóloga) - O ambiente de trabalho e os efeitos psicossomáticos na saúde do indivíduo.</p>
                        <p class="reference">
                            Dejours, C. (1999). A banalização da injustiça social. 1ª ed. Rio de Janeiro: Fundação Getúlio Vargas.
                        </p>
                        <p class="reference">MARX, K. O capital. V. I, tomo 1. São Paulo: Abril Cultural, 1983.</p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a>Compartilhe</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/intent/tweet?url=https://www.listenonline.com.br/blog/{{ article_slug }}&text=" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.listenonline.com.br/blog/{{ article_slug }}&title=&summary=&source=" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="https://pinterest.com/pin/create/button/?url=https://www.listenonline.com.br/blog/{{ article_slug }}&media=&description=" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                <li><a href="mailto:info@example.com?&subject=&body=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bx-envelope'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog"><i class='bx bx-left-arrow-alt'></i> Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <app-blog-outros-artigos [article_id]="article_id"></app-blog-outros-artigos>
                    <app-blog-tags></app-blog-tags>
                    <app-blog-cta></app-blog-cta>
                </aside>
            </div>
        </div>
    </div>
</section>
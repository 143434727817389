import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AllServices} from 'src/app/services/all.service';
import {Options} from '@angular-slider/ngx-slider';
import {format, add} from 'date-fns';
import {zonedTimeToUtc, utcToZonedTime} from 'date-fns-tz'
import {Router} from '@angular/router';
import {Portal} from '@angular/cdk/portal';
import {User} from 'src/app/model/User';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    pattern = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"

    professionalForm: FormGroup;

    sending: false;
    specialties = [];
    audiences = [];
    professionals = [];
    pageProfessionals = [];
    days = [];

    today = new Date();

    selectedTime;
    selectedProfessional;

    searching = false;
    searched = false;

    minValue: number = 50;
    maxValue: number = 450;
    options: Options = {
        floor: 0,
        ceil: 500,
        getPointerColor: (value: number): string => {
            return '#5f9034';
        },
        getSelectionBarColor: (value: number): string => {
            return '#5f9034';
        }
    };


    //Paginator variables:
    length;
    currentPage = 0;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    pageEvent: PageEvent;

    constructor(private allServices: AllServices,
        private router: Router,
        private toast: ToastrService,
        private _formBuilder: FormBuilder, ) {}

    ngOnInit(): void {
        this.days = this.getDays(this.today);
        this.professionalForm = this.createProfessionalForm();

        this.allServices.getSpecialties().subscribe(data => {
            this.specialties = data.specialties;
        })
        this.allServices.getAudiences().subscribe(data => {
            this.audiences = data.audiences;
        })
    }

    ngAfterViewInit() {
        
    }

    createProfessionalForm(): FormGroup {
        return this._formBuilder.group({
            name: '',
            specialties: '',
            audiences: '',
            gender: '',
            appointment_value: '',
            start_date: new Date(),
            order: 'ANY',
        });
    }

    clearSearch() {
        localStorage.removeItem('search');
        localStorage.removeItem('page');
        localStorage.removeItem('lastSearch');
        this.professionalForm = this.createProfessionalForm();
        this.professionals = [];
        this.searched = false;
        this.adjustData();
    }

    searchUsers() {
        if (this.professionalForm.invalid) {
            this.toast.error('Verifique os dados e realize a consulta novamente!');
            return;
        }
        this.searching = true;

        //Nome
        var search = '';
        search = 'startValue=' + this.professionalForm.controls.appointment_value.value[0];
        search = search + '&endValue=' + this.professionalForm.controls.appointment_value.value[1];

        if (this.professionalForm.controls.name.value) {
            search = '&name=' + this.professionalForm.controls.name.value
        }

        //Specialties
        if (this.professionalForm.controls.specialties.value) {
            if (this.professionalForm.controls.specialties.value.length > 0) {

                if (search.length > 0) search = search + '&';
                search = search + 'specialties=';
                var first = true;
                for (let data of this.professionalForm.controls.specialties.value) {
                    if (!first) {
                        search = search + ',';
                    }
                    search = search + data;
                    first = false;
                }
            }
        }

        //Audiences
        if (this.professionalForm.controls.audiences.value) {
            if (this.professionalForm.controls.audiences.value.length > 0) {

                if (search.length > 0) search = search + '&';
                search = search + 'audiences=';
                var first = true;
                for (let data of this.professionalForm.controls.audiences.value) {
                    if (!first) {
                        search = search + ',';
                    }
                    search = search + data;
                    first = false;
                }
            }
        }

        // Gender
        if (this.professionalForm.controls.gender.value) {
            if (search.length > 0) search = search + '&';
            search = search + 'gender=' + this.professionalForm.controls.gender.value;
        }

        //Date 
        const output = format(this.professionalForm.controls.start_date.value, this.pattern)
        search = search + '&startDate=' + output

        // Atualizando dates:
        this.days = this.getDays(this.professionalForm.controls.start_date.value);

        // Salvando os dados da pesquisa:
        let formData = [];
        for (let field in this.professionalForm.controls) {

            formData.push({field: field, value: this.professionalForm.controls[field].value})
        }
        //localStorage.setItem('search', JSON.stringify(formData));

        //console.log(this.professionalForm);
        this.allServices.searchUsers(search).subscribe(data => {
            this.professionals = this.sortProfessionals(data.users, this.professionalForm.controls.order.value);

            this.searched = true;
            //localStorage.setItem('lastSearch', JSON.stringify(this.professionals));
            //localStorage.removeItem('page');
            this.currentPage = 0;
            this.adjustData();
            //console.log(this.professionals);
            this.searching = false;
        })
    }

    adjustData() {
        this.length = this.professionals.length;
        for (let pro of this.professionals) {
            if (!pro.profile_img_url) {
                pro.profile_img_url = 'assets/images/profile.jpg';
            }
            this.updateProTimes(pro, this.days);
        }
        const end = (this.currentPage + 1) * this.pageSize;
        const start = this.currentPage * this.pageSize;
        this.pageProfessionals = this.professionals.slice(start, end);
        setTimeout(() => this.paginator.pageIndex = this.currentPage);
    }

    getDays(start) {
        let days = [];
        for (let i = 0; i < 7; i++) {
            days.push(add(start, {days: i}));
        }
        return days;
    }


    updateProTimes(pro, days) {
        let pos = 0;
        let dates = [];

        pro.screenAvailableTimes = [];
        pro.days = days;

        for (let av of pro.availabilities) {
            const utcDate = zonedTimeToUtc(av.datetime, pro.timezone);
            //console.log(format(utcDate, this.pattern));
            // TODO - converter para o timezone do usuario
            if (utcDate.getDay() == days[pos].getDay()) {
                dates.push(utcDate);
            } else {
                pro.screenAvailableTimes.push({date: days[pos], times: dates});
                while (pos < days.length) {
                    if (utcDate.getDay() == days[pos].getDay()) {
                        dates = [];
                        dates.push(utcDate);
                        break;
                    } else {
                        pos = pos + 1;
                    }
                }
            }
        }
        if (dates.length > 0) {
            pro.screenAvailableTimes.push({date: days[pos], times: dates});
        }
    }

    changePage(event) {
        console.log(event);
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        let page = {currentPage: this.currentPage, pageSize: this.pageSize};
        localStorage.setItem('page', JSON.stringify(page));
        const end = (this.currentPage + 1) * this.pageSize;
        const start = this.currentPage * this.pageSize;
        this.pageProfessionals = this.professionals.slice(start, end);
        return event;
    }

    public sortProfessionals(professionals: User[], payload: string): any {
        if (payload === 'A') {
            return professionals.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                } else if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'VA') {
            return professionals.sort((a, b) => {
                if (a.appointment_value < b.appointment_value) {
                    return -1;
                } else if (a.appointment_value > b.appointment_value) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'VD') {
            return professionals.sort((a, b) => {
                if (a.appointment_value > b.appointment_value) {
                    return -1;
                } else if (a.appointment_value < b.appointment_value) {
                    return 1;
                }
                return 0;
            })
        } else {
            return professionals.sort((a, b) => {
                return Math.floor(Math.random() * (1 + 1 + 1)) - 1;
            })
        }
    }
}

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AllServices} from 'src/app/services/all.service';
import {ValidateBrService} from 'angular-validate-br';
import {Title, Meta} from '@angular/platform-browser';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-profissional-inscreva-se',
    templateUrl: './profissional-inscreva-se.component.html',
    styleUrls: ['./profissional-inscreva-se.component.scss']
})
export class ProfissionalInscrevaSeComponent implements OnInit {

    pacient = false;
    register = true;

    crp_regions = [];

    sending = false;

    contactForm: FormGroup;
    professionalForm: FormGroup;

    constructor(private allServices: AllServices,
        private _formBuilder: FormBuilder,
        private toast: ToastrService,
        private validateBrService: ValidateBrService,
        private router: Router,
        private titleService: Title,
        private metaTagService: Meta) {
        this.createTags();
    }

    ngOnInit(): void {
        this.allServices.getRegions().subscribe(data => {
            this.crp_regions = data.crp_regions;
        })
        this.contactForm = this.createContactForm();
        this.professionalForm = this.createProfessionalForm();
        this.professionalForm.setValidators(checkPasswords);
    }

    createContactForm(): FormGroup {
        return this._formBuilder.group({
            name: [''],
            email: [''],
            type: ['CUS'],
            password: ['']
        });
    }

    createProfessionalForm(): FormGroup {
        return this._formBuilder.group({
            id: [],
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            type: ['PRO'],
            professional_type_id: ['1'],
            gender: ['', [Validators.required]],
            birth_date: ['', [Validators.required, DateValidator.ptDate]],
            crp_region_id: ['', [Validators.required]],
            id_card_number: ['', [Validators.required]],
            registration_number: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            mobile: ['', [Validators.required]],
            vat: ['', [Validators.required, this.validateBrService.cpf]],
            password: ['', [Validators.required, PasswordValidator.strong]],
            password_confirm: ['', [Validators.required, PasswordValidator.strong]],

        });

    }

    changeForm() {
        this.pacient = !this.pacient;
    }

    sendProfessionalForm() {
        if (this.professionalForm.invalid) {
            this.toast.error('Formulário incompleto!', 'Por favor, preencha o formulário com todos os campos obrigatórios!')
        } else {
            this.sending = true
            this.allServices.postUser(this.professionalForm.value).subscribe(data => {
                this.toast.success('Seu cadastro foi criado com sucesso!', 'Sucesso!');
                this.register = false;
                this.sending = false;
                window.scroll(0, 0);
            }, error => {
                this.toast.error(error.error.message, 'Erro!');
                //console.log(error)
                this.sending = false;
            })
        }

    }

    callLogin() {
        window.location.href = environment.app_url + "pages/auth/login";
    }

    sendCustomerForm() {
        console.log(this.contactForm);
    }

    createTags() {
        this.titleService.setTitle('Para o Profissional > Inscreva-se | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({name: 'description', content: 'Venha participar como profissional parceiro, preencha o formulário e inscreva-se.'});
        this.metaTagService.updateTag({name: 'robots', content: 'index, follow'});
        this.metaTagService.updateTag({name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});
        this.metaTagService.updateTag({name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});

        this.metaTagService.updateTag({property: 'og:locale', content: "pt_BR"});
        this.metaTagService.updateTag({property: 'og:type', content: "article"});
        this.metaTagService.updateTag({property: 'og:title', content: 'Para o Profissional > Inscreva-se | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({property: 'og:description', content: ''});
        this.metaTagService.updateTag({property: 'og:site_name', content: "Listen Online"});
        this.metaTagService.updateTag({property: 'og:url', content: window.location.href});

        this.metaTagService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.metaTagService.updateTag({name: 'twitter:title', content: 'Para o Profissional > Inscreva-se | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({name: 'twitter:description', content: ''});
        this.metaTagService.updateTag({name: 'twitter:url', content: window.location.href});
    }
}

export const checkPasswords: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const pass1 = control.get('password');
    const pass2 = control.get('password_confirm');

    return pass1 && pass2 && pass1.value === pass2.value ? null : {'notMatch': true};
};

export class PasswordValidator {

    public static strong(control: FormControl): ValidationErrors | null {
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        const valid = hasNumber && hasUpper && hasLower && control.value.length > 5;
        if (!valid) {
            // return what´s not valid
            return {strong: true};
        }
        return null;
    }
}

export class DateValidator {

    static ptDate(control: FormControl): {[key: string]: any} {
        let ptDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

        if (!control.value.match(ptDatePattern))
            return {"ptDate": true};

        return null;
    }

    static usDate(control: FormControl): {[key: string]: any} {
        let usDatePattern = /^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/;

        if (!control.value.match(usDatePattern))
            return {"usDate": true};

        return null;
    }
}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';

@Component({
    selector: 'app-profissional',
    templateUrl: './profissional.component.html',
    styleUrls: ['./profissional.component.scss']
})
export class ProfissionalComponent implements OnInit {

    constructor(private router: Router,
        private titleService: Title,
        private metaTagService: Meta) {
        this.createTags();
    }

    ngOnInit(): void {
    }

    createTags() {
        this.titleService.setTitle('Profissional > O que é | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({name: 'description', content: 'Temos um ambiente seguro para que você atenda seus clientes por videochamada, com um escritório virtual completo à sua disposição.'});
        this.metaTagService.updateTag({name: 'robots', content: 'index, follow'});
        this.metaTagService.updateTag({name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});
        this.metaTagService.updateTag({name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});

        this.metaTagService.updateTag({property: 'og:locale', content: "pt_BR"});
        this.metaTagService.updateTag({property: 'og:type', content: "article"});
        this.metaTagService.updateTag({property: 'og:title', content: 'Profissional > O que é | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({property: 'og:description', content: ''});
        this.metaTagService.updateTag({property: 'og:site_name', content: "Listen Online"});
        this.metaTagService.updateTag({property: 'og:url', content: window.location.href});

        this.metaTagService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.metaTagService.updateTag({name: 'twitter:title', content: 'Profissional > O que é | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({name: 'twitter:description', content: ''});
        this.metaTagService.updateTag({name: 'twitter:url', content: window.location.href});
    }

}

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ professionals.length == 0 ? 'Detalhes do Profissional' : professionals[0].name }}</h2>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center" *ngIf=' professionals.length == 0'>
                <h5>Nenhum profissional encontrado.</h5>
            </div>
            <div class="col-lg-12" *ngFor='let pro of professionals'>
                 <app-professional-details [pro]='pro' mode='Complete'>
                </app-professional-details>
            </div>
        </div>

    </div>
</div>
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpErrorResponse} from '@angular/common/http';

import {Observable, throwError, of, BehaviorSubject} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {User} from '../model/User';
import { environment } from 'src/environments/environment';

var sha1 = require('sha1');

@Injectable({
    providedIn: 'root',
})
export class AllServices {

    private _onUserChanged: BehaviorSubject<any>;
    private _onLanguageChanged: BehaviorSubject<any>;

    public selectedProfessional: User;
    
    pattern = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";

    API_URL = environment.api_url;

    user: User;
    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJMaXN0ZW5PbmxpbmUgU2VydmVyIiwic3ViIjoiMCIsInRpbWVzdGFtcCI6IjIwMjAtMDktMjNUMDg6MTQ6MzktMDM6MDAiLCJ0eXBlIjoiU1lTIiwibmFtZSI6IlN5c3RlbSIsImVtYWlsIjoic3lzdGVtIn0.fJ_-eBOa-_XPTSaeFnryiLhDTDGeewJZVwKYHFCa40o',
        }),
    };

    constructor(
        private http: HttpClient,
    ) {
        this._onUserChanged = new BehaviorSubject(null);
        this._onLanguageChanged = new BehaviorSubject(null);
    }

    getRegions(): Observable<any> {
        let headers = this.headers;
        return this.http.get<any>(this.API_URL + 'crp_regions/', headers);
    }

    postUser(data): Observable<any> {
        let headers = this.headers;
        var encripted = JSON.parse(JSON.stringify(data));
        encripted.password = sha1(encripted.password);
        delete encripted['password_confirm'];
        return this.http.post<any>(this.API_URL + 'users/', encripted, headers);
    }

    postAppointments(body): Observable<any> {
        let headers = this.headers;
        return this.http.post<any>(this.API_URL + 'appointments/',body, headers);
    }
    
    activateUser(id): Observable<any> {
        let headers = this.headers;
        return this.http.put<any>(this.API_URL + 'users/activate/' + id, null, headers);
    }
    
    postContact(data): Observable<any> {
        let headers = this.headers;
        return this.http.post<any>(this.API_URL + 'website_contacts/', data, headers);
    }

    getSpecialties(): Observable<any> {
        let headers = this.headers;
        return this.http.get<any>(this.API_URL + 'specialties/null/1', headers);
    }

    getAudiences(): Observable<any> {
        let headers = this.headers;
        return this.http.get<any>(this.API_URL + 'audiences', headers);
    }

    getProfessionalAvailibilities(id, date): Observable<any> {
        let headers = this.headers;
        return this.http.get<any>(this.API_URL + 'professionals/availabilities/' + id + '?startDate=' + date, headers);
    }

    searchUsers(query: string): Observable<any> {
        let headers = this.headers;
        var searchquery = ''
        if (query) {
            searchquery = '?' + query
        }
        return this.http.get<any>(this.API_URL + 'users/search/PRO' + searchquery, headers);
    }

    
    postSubscribe(data): Observable<any> {
        let headers = this.headers;
        return this.http.post<any>(this.API_URL + 'users/subscribe/', data, headers);
    }
}




<section class="slider-area-2">
    <div class="home-slider owl-carousel owl-theme">
        <!--
        <div class="single-slider single-slider-bg-newyear">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12 text-center">
                                <div class="slider-content one">
                                    <h1>Feliz 2021</h1>
                                    <p>Que venha um novo tempo de união e esperança, onde possamos juntos construir uma nova realidade.</p>
                                    <!--div class="slider-btn text-center">
                                        <a routerLink="/pesquisa" class="box-btn">Encontre um Profissional</a> 
                                        <a routerLink="/para-voce" class="box-btn border-btn">Saiba mais</a>
                                    </div-->
                                <!--/div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        -->

        <div class="single-slider single-slider-bg-1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12 text-center">
                                <div class="slider-content one">
                                    <h1>Conectividade a seu serviço</h1>
                                    <p>Encontre psicólogos de diversas especialidades para consultas por videochamada. Você escolhe o profissional de sua preferência e o horário de suas consultas, adaptando-os às suas necessidades.</p>
                                    <div class="slider-btn text-center">
                                        <a routerLink="/pesquisa" class="box-btn">Encontre um Profissional</a> 
                                        <a routerLink="/para-voce" class="box-btn border-btn">Saiba mais</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-slider single-slider-bg-2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12 text-center">
                                <div class="slider-content one">
                                    <h1>Toda a segurança das consultas on-line</h1>
                                    <p>Para os profissionais, oferecemos um escritório virtual completo, com programação de agenda, contato com os clientes e controle financeiro. Tudo de maneira segura e automatizada, de acordo com as diretrizes e recomendações do CFP para atendimento online.</p>
                                    <div class="slider-btn text-center">
                                        <a routerLink="/profissional/inscreva-se" class="box-btn">Inscreva-se!</a>
                                        <a routerLink="/profissional" class="box-btn border-btn">Saiba mais</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
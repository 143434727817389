import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors, FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
    selector: 'confirm-schedule-dialog',
    templateUrl: './confirm-schedule.component.html',
    styleUrls: ['./confirm-schedule.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ConfirmScheduleDialogComponent {
    public message: string;
    public action: string;
    public professional: any;
    public time: any;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ConfirmScheduleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        // Set the defaults
        this.message = _data.message;
        this.action = _data.action;
        this.professional = _data.professional;
        this.time = _data.time;
    }


    save() {
        this.matDialogRef.close(['yes']);
    }
}


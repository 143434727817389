import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { SolutionsComponent } from './components/pages/solutions/solutions.component';
import { SolutionsDetailsComponent } from './components/pages/solutions-details/solutions-details.component';
import { CaseStudiesComponent } from './components/pages/case-studies/case-studies.component';
import { CaseStudiesDetailsComponent } from './components/pages/case-studies-details/case-studies-details.component';

import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogDetails08Component } from './components/pages/blog-details-08/blog-details-08.component';
import { BlogDetails07Component } from './components/pages/blog-details-07/blog-details-07.component';
import { BlogDetails06Component } from './components/pages/blog-details-06/blog-details-06.component';
import { BlogDetails05Component } from './components/pages/blog-details-05/blog-details-05.component';
import { BlogDetails04Component } from './components/pages/blog-details-04/blog-details-04.component';
import { BlogDetails03Component } from './components/pages/blog-details-03/blog-details-03.component';
import { BlogDetails02Component } from './components/pages/blog-details-02/blog-details-02.component';
import { BlogDetails01Component } from './components/pages/blog-details-01/blog-details-01.component';
import { BlogTagsComponent } from './components/pages/blog-tags/blog-tags.component';
import { BlogCtaComponent } from './components/pages/blog-cta/blog-cta.component';
import { BlogOutrosArtigosComponent } from './components/pages/blog-outros-artigos/blog-outros-artigos.component';

import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ContatoComponent } from './components/pages/contato/contato.component';
import { AllServices } from './services/all.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { ToastrModule } from 'ngx-toastr';

import { ConfirmarCadastroComponent } from './components/pages/confirmar-cadastro/confirmar-cadastro.component';
import { ProfissionalInscrevaSeComponent } from './components/pages/profissional-inscreva-se/profissional-inscreva-se.component';
import { RouterModule } from '@angular/router';
import { ParaVoceComponent } from './components/pages/para-voce/para-voce.component';
import { ParaVoceComoFuncionaComponent } from './components/pages/para-voce-como-funciona/para-voce-como-funciona.component';
import { PesquisaComponent } from './components/pages/pesquisa/pesquisa.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule } from '@angular/material/core';
import { NgModule, LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { NgxSummernoteModule } from 'ngx-summernote';
registerLocaleData(localePt);

import { MatPaginatorIntlPt } from "./shared/search/MatPaginatorIntlPt"
import { DateFnsModule } from 'ngx-date-fns';
import { DetalhesComponent } from './components/pages/detalhes/detalhes.component';
import { SearchComponent } from './shared/search/search.component';
import { ParaVoceCadastreSeComponent } from './components/pages/para-voce-cadastre-se/para-voce-cadastre-se.component';
import { ProfissionalComponent } from './components/pages/profissional/profissional.component';
import { ProfissionalComoFuncionaComponent } from './components/pages/profissional-como-funciona/profissional-como-funciona.component';
import { SobreComponent } from './components/pages/sobre/sobre.component';
import { ProfessionalDetailsComponent } from './shared/professional-details/professional-details.component';
import { ConfirmScheduleDialogComponent } from './shared/confirm-schedule/confirm-schedule.component';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        FooterComponent,
        HomeOneComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        SolutionsComponent,
        SolutionsDetailsComponent,
        CaseStudiesComponent,
        CaseStudiesDetailsComponent,
        BlogComponent,
        BlogDetailsComponent,
        BlogDetails08Component,
        BlogDetails07Component,
        BlogDetails06Component,
        BlogDetails05Component,
        BlogDetails04Component,
        BlogDetails03Component,
        BlogDetails02Component,
        BlogDetails01Component,
        BlogTagsComponent,
        BlogCtaComponent,
        BlogOutrosArtigosComponent,
        TeamComponent,
        PricingComponent,
        GalleryComponent,
        TestimonialsComponent,
        SignUpComponent,
        SignInComponent,
        ErrorComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        ContatoComponent,
        ConfirmarCadastroComponent,
        ProfissionalInscrevaSeComponent,
        ParaVoceComponent,
        ParaVoceComoFuncionaComponent,
        ParaVoceCadastreSeComponent,
        PesquisaComponent,
        DetalhesComponent,
        ProfissionalComponent,
        ProfissionalComoFuncionaComponent,
        SobreComponent,

        //Componentes
        SearchComponent,
        ProfessionalDetailsComponent,
        ConfirmScheduleDialogComponent,

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxMaskModule.forRoot(),
        NgxSliderModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatIconModule,
        MatToolbarModule,
        NgxSummernoteModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-left',
        }),
        DateFnsModule.forRoot()
    ],
    providers: [
        AllServices,
        MatDatepickerModule,
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlPt },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

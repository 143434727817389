<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Sobre a Listen</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Sobre a Listen</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Quem somos</span>
                        <h2>Estamos ao seu lado para gerar resultados</h2>
                        <p>Atenta à tendência de crescimento do mercado de terapias on-line, a Listen surgiu com o objetivo de oportunizar aos profissionais de psicologia o ingresso neste novo segmento de mercado, oferecendo-lhes uma opção segura, confiável e dentro de todas as normas legais para expandir sua base de clientes e crescer cada vez mais.</p>
                        <p>Para os clientes, buscamos oferecer uma ampla gama de profissionais qualificados para atendimento on-line prático e seguro. Trabalhamos com qualidade, por um acesso ágil e com soluções tecnológicas, proporcionando serviços com eficiência e segurança.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img3.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="company-img">
                    <img src="assets/images/company/company-img4.png" alt="" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Missão, Visão e Valores</h2>
                    <p>Nossa missão é escutar e produzir resultados que atendam de forma eficiente e satisfatória aos nossos clientes.</p><p> Temos como visão sermos reconhecidos como uma plataforma on-line de excelência no atendimento de nossos clientes e valores como excelência, ética, respeito, profissionalismo e solidariedade.</p>
                    <!--a routerLink="/contato" class="box-btn">Fale Conosco</a-->
                </div>
            </div>
        </div>
    </div>
</section>

<!--section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Features</span>
                        <h2>We Have Also Some Features That Provided by Aiva</h2>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt consectetur, beatae quod eaque reprehenderit non ab quibusdam doloribus voluptatibus! Voluptatum aspernatur quasi id dolore doloremque quo vero</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Freelancing Traning Course</li>
                        <li><i class="flaticon-correct"></i> Technological Consultation</li>
                        <li><i class="flaticon-correct"></i> Monthly Paid Workspace</li>
                        <li><i class="flaticon-correct"></i> IT Learning Institute</li>
                        <li><i class="flaticon-correct"></i> Digital Marketing</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>

        <div class="client-wrap owl-carousel owl-theme">
            <div class="single-client">
                <img src="assets/images/client/1.jpg" alt="img">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                <h3>Steven Jony</h3>
                <span>CEO of Company</span>
            </div>
            
            <div class="single-client">
                <img src="assets/images/client/2.jpg" alt="img">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                <h3>Omit Jacson</h3>
                <span>Company Founder</span>
            </div>
        </div>
    </div>
</section-->
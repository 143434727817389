import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-blog-details-06',
    templateUrl: './blog-details-06.component.html',
    styleUrls: ['./blog-details-06.component.scss']
})
export class BlogDetails06Component implements OnInit {
    public article_id;
    public article_slug;

    constructor(private titleService: Title,
        private metaTagService: Meta) {
        this.createTags();
        this.article_id = '6';
        this.article_slug = 'o-trabalho-esta-te-matando';
    }

    ngOnInit(): void {
    }

    createTags() {
        this.titleService.setTitle('O trabalho está te matando?  | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({ name: 'description', content: 'O trabalho é uma categoria fundamental à existência humana! É através da atividade do trabalho que o indivíduo transforma os recursos disponíveis na natureza em produtos que atendam às suas necessidades materiais.' });
        this.metaTagService.updateTag({ name: 'robots', content: 'index, follow' });
        this.metaTagService.updateTag({ name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' });
        this.metaTagService.updateTag({ name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' });

        this.metaTagService.updateTag({ property: 'og:locale', content: "pt_BR" });
        this.metaTagService.updateTag({ property: 'og:type', content: "article" });
        this.metaTagService.updateTag({ property: 'og:title', content: 'O trabalho está te matando?  | Listen - Estamos aqui para ouvir você' });
        this.metaTagService.updateTag({ property: 'og:description', content: '' });
        this.metaTagService.updateTag({ property: 'og:site_name', content: "Listen Online" });
        this.metaTagService.updateTag({ property: 'og:url', content: window.location.href });

        this.metaTagService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.metaTagService.updateTag({ name: 'twitter:title', content: 'O trabalho está te matando?  | Listen - Estamos aqui para ouvir você' });
        this.metaTagService.updateTag({ name: 'twitter:description', content: '' });
        this.metaTagService.updateTag({ name: 'twitter:url', content: window.location.href });
    }

}

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Como Funciona</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/para-voce">Para Você </a></li>
                <li class="active">Como Funciona</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="pt-100 pb-70 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Nosso Processo</span>
            <h2>É muito fácil utilizar a Listen</h2>
            <p>Confira quais os passos necessários para fazer a sua consulta online:</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Encontre um profissional</h3>
                        <p>Faça uma pesquisa e enconte o profissional ideal para seu atendimento. São especialistas em diversas áreas.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Verifique sua agenda</h3>
                        <p>Confira o perfil e currículo do profissional, e também a sua disponibilidade de horários de atendimento.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Selecione um horário</h3>
                        <p>Clique sobre o horário desejado para sua consulta, faça seu cadastro e, se já cadastrado, efetue o login na plataforma.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Faça o pagamento</h3>
                        <p>Na plataforma segura Listen, escolha a melhor forma de pagamento (Cartão de Crédito, Cartão de Débito ou Boleto) e confirme a sua consulta.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Tecnologia a seu serviço</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <ul class="accordion">
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title active" href="javascript:void(0)"><i class="bx bx-plus"></i> Quais critérios devo atender para usar os serviços da plataforma?</a>
                                <p class="accordion-content show">Escolha um lugar tranquilo com acesso à internet e um computador ou celular com câmera. </p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> A plataforma é segura?</a>
                                <p class="accordion-content">Sim, nossa plataforma é segura. Seus dados serão processados e arquivados digitalmente, de maneira confidencial. Nossos psicólogos são altamente capacitados e trabalhamos dentro das normas exigidas pelo CFP – Conselho Federal de Psicologia.</p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> Posso me sentir seguro quanto ao sigilo sobre meus dados pessoais?</a>
                                <p class="accordion-content">Certamente. Em primeiro lugar, seus dados serão tratados de maneira confidencial. Nossos psicólogos são altamente capacitados e garantimos a máxima qualidade ao indicarmos um psicólogo para você. </p>
                            </li>
                            
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> As consultas são sigilosas?</a>
                                <p class="accordion-content">Garantimos o total sigilo para pacientes e psicólogos. Todas as informações trocadas durante as consultas ficarão entre eles, pois somente psicólogo e paciente têm acesso a cada sessão. Nenhuma sessão será gravada. </p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> Como faço para efetuar os pagamentos dos serviços que eu contratar?</a>
                                <p class="accordion-content">Disponibilizaremos ferramentas seguras para pagamento via internet, e os pagamentos são feitos dentro da área segura depois de seu login na plataforma Listen Online.</p>
                            </li>
                            
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> Posso usar métodos de pagamento diferentes ou devo usar sempre o mesmo?</a>
                                <p class="accordion-content">Você pode escolher o tipo de pagamento que for melhor para você a cada sessão marcada.</p>
                            </li>
                            
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> É seguro utilizar o cartão de crédito?</a>
                                <p class="accordion-content">Sim. Nosso sistema de pagamentos é gerenciado pela <a href="http://www.safe2pay.com.br">Safe2Pay</a>, também utilizado por grandes empresas e desenvolvido de forma a garantir a segurança em todas as transações financeiras.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Qualidade Listen</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <ul class="accordion">
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title active" href="javascript:void(0)"><i class="bx bx-plus"></i> Qual a filosofia de qualidade da Listen no que tange atendimento e serviços?</a>
                                <p class="accordion-content show">A Listen tem como objetivo escutar as pessoas no sentido de ouvir a queixa e proporcionar a solução para o problema. Trabalhamos com qualidade, por um acesso ágil e com soluções tecnológicas, proporcionando serviços com eficiência e segurança.</p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i> Como é garantida a segurança dos serviços?</a>
                                <p class="accordion-content">Toda a plataforma Listen é desenvolvida em integração com softwares com certificação de segurança, garantindo a privacidade dos dados de todos os usuários.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {
    }

    callLogin() {
        window.location.href = environment.app_url + "pages/auth/login";
    }
}

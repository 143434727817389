// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    api_url: 'https://listenonline.resolutive.com.br/server/api/',
    //api_url: 'http://localhost/www.listenonline.com.br/server/api/',
    site_url: 'https://listenonline.resolutive.com.br/',
    //site_url: 'http://localhost:4201/',
    app_url: 'https://applistenonline.resolutive.com.br/',
    //app_url: 'http://localhost:4200/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

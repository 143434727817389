<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Qual é o papel do psicólogo?</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/blog">Blog </a></li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100 pt-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <!--div class="article-image">
                        <img src="assets/images/blog-details/blog-details.jpg" alt="">
                    </div-->

                    <div class="article-content">
                        <h3 style="margin-top: 0px;">Qual é o papel do psicólogo?</h3>

                        <div class="entry-meta mb-20">
                            <ul>
                                <li><span>Publicado em:</span>&nbsp;12 de Janeiro de 2020</li>
                                <li><span>Por:</span>&nbsp;<a routerLink="/sobre">Equipe Listen</a></li>
                            </ul>
                        </div>

                        <p>A psicologia oferece uma solução alternativa para os conflitos sociais, emocionais, individuais e elabora um saber sobre si mesmo, sobre o próprio mundo. O psicólogo tem como papel ajudar o paciente e, sobretudo, estruturar uma
                            forma de relação da pessoa com seu meio, ajudá-la a configurar um mundo onde o indivíduo ocupa um lugar e assim materializar seus interesses sociais.</p>
                        <p>Desta forma, é importante iniciar o processo de transformação, a “conscientização”. Este processo supõe três aspectos:</p>
                        <p>I. O ser humano transforma-se ao modificar sua realidade, o que só ocorre através do diálogo.</p>
                        <p>II. Nova forma de consciência: o indivíduo capta os mecanismos que o oprimem e abre o horizonte para novas possibilidades de ação.</p>
                        <p>III. Novo saber de si, sobre sua realidade e sobre sua identidade social.</p>

                        <div class="mb-3 mt-3">
                            <figure>
                                <img src="assets/images/blog/art2_01.jpg" alt="">
                                <figcaption style="text-align: center;"><small>O psicólogo ajuda o indivíduo a configurar um mundo onde ele ocupa um lugar, ajudando-o assim a materializar seus interesses sociais.<br>Créditos: Freepik</small></figcaption>
                            </figure>
                        </div>
                        <p>O indivíduo começa a se descobrir, a identificar suas ações transformadoras das coisas e seu papel ativo nas relações com os demais, chegando, com a ajuda do psicólogo, à recuperação de sua memória histórica, oferecendo uma base
                            para a autonomia de seu futuro.</p>
                        <p>A conscientização não consiste, portanto, em uma simples mudança de opinião sobre a realidade; ela supõe uma mudança do indivíduo no processo de mudar sua relação com o meio ambiente e principalmente com os demais.</p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art2_02.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Atendimento online por videochamada tem a mesma eficiência que o presencial, sendo uma opção prática e econômica.<br>Créditos: Freepik</small></figcaption>
                            </figure>
                        </div>
                        <p>Dada a crescente demanda relacionada à saúde mental e muitas vezes à necessidade de respostas rápidas e eficientes - em algumas situações com a necessidade de deslocamento para um atendimento com um psicólogo - temos a opção de
                            atendimento psicológico por meio da tecnologia da informação: o atendimento online por videochamada, que proporciona algumas vantagens como: </p>
                        <ul class="article-ul">
                            <li>Mais tempo livre para você;</li>
                            <li>Proximidade entre profissional e paciente;</li>
                            <li>Consultas com custo menor;</li>
                            <li>Economia no transporte (deslocamento);</li>
                            <li>Segurança nas informações e ética profissional;</li>
                            <li>Mesma eficiência das consultas presenciais.</li>
                        </ul>

                        <p class="mt-5"><small><b>REFERÊNCIAS:</b></small></p>
                        <p class="reference">
                            Martín-Baró, Ignácio; O papel do Psicólogo; Estudos de Psicologia 1996, 2(1), 7-27 .
                        </p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a>Compartilhe</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/intent/tweet?url=https://www.listenonline.com.br/blog/{{ article_slug }}&text=" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.listenonline.com.br/blog/{{ article_slug }}&title=&summary=&source=" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="https://pinterest.com/pin/create/button/?url=https://www.listenonline.com.br/blog/{{ article_slug }}&media=&description=" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                <li><a href="mailto:info@example.com?&subject=&body=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bx-envelope'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog"><i class='bx bx-left-arrow-alt'></i> Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <app-blog-outros-artigos [article_id]="article_id"></app-blog-outros-artigos>
                    <app-blog-tags></app-blog-tags>
                    <app-blog-cta></app-blog-cta>
                </aside>
            </div>
        </div>
    </div>
</section>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Atendimento online é permitido?</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/blog">Blog </a></li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100 pt-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <!--div class="article-image">
                        <img src="assets/images/blog-details/blog-details.jpg" alt="">
                    </div-->

                    <div class="article-content">
                        <h3 style="margin-top: 0px">Atendimento online é permitido? Ele é eficaz? E a relação paciente e profissional, como fica?</h3>

                        <div class="entry-meta mb-20">
                            <ul>
                                <li><span>Publicado em:</span>&nbsp;15 de Janeiro de 2020</li>
                                <li><span>Por:</span>&nbsp;<a routerLink="/sobre">Equipe Listen</a></li>
                            </ul>
                        </div>

                        <p>Frequentemente surgem dúvidas em relação ao atendimento psicológico online, então, como somos uma plataforma voltada ao atendimento psicológico por videochamada, vamos falar um pouco desta modalidade.</p>
                        <h4>A modalidade é permitida?</h4>
                        <p>Sim, o Conselho Federal de Psicologia (CFP) publicou a Resolução CFP nº 11/2018, que atualiza a Resolução CFP nº 11/2012 sobre atendimento psicológico online e demais serviços realizados por meios tecnológicos de comunicação a
                            distância.
                        </p>
                        <p><b>Curiosidade</b>: Esta modalidade já era utilizada por outros países como Canadá, Austrália e Reino Unido.</p>
                        <p>A psicóloga ou o psicólogo poderão oferecer consultas ou atendimentos psicológicos de diferentes tipos por meio das tecnologias da informação e comunicação. Cada tecnologia utilizada deverá guardar coerência e fundamentação na
                            ciência, na legislação e nos parâmetros éticos da profissão. O atendimento, portanto, não poderá ocorrer de qualquer maneira, cabendo ao profissional fundamentar, inclusive nos registros da prestação do serviço, se a tecnologia
                            utilizada é tecnicamente adequada, metodologicamente pertinente e eticamente respaldada.</p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art5_02.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Créditos: Freepik</small></figcaption>
                            </figure>
                        </div>
                        <p>Para prestar serviços psicológicos online é obrigatório o cadastro no e-Psi. O e-Psi lista as (os) profissionais que estão autorizadas (os) pelo Sistema Conselhos de Psicologia a prestarem serviços psicológicos on-line. Se a (o)
                            profissional não estiver listada (o), ela (e) não está autorizada (o) a prestar esse serviço. Cadastre-se aqui: <a href="https://e-psi.cfp.org.br/">https://e-psi.cfp.org.br/</a>.</p>

                        <h4>Ele é eficaz como o presencial?</h4>

                        <p>Sim, a psicoterapia online tem os mesmos benefícios que a presencial e é eficaz, pois quem vai determinar os benefícios e sua eficácia e o próprio usuário e seu desejo de mudança. Mesmo com o crescimento do acesso a este tipo de
                            modalidade, a psicoterapia virtual provoca uma dúvida: será que ela é tão eficaz quanto a ao vivo e em cores? </p>
                        <p>Maria Adélia Minghelli Pieta, doutora em psicologia pela Universidade Federal do Rio Grande do Sul, assegura que é eficaz. Em seu estudo sobre o tema, ela coordenou uma equipe de oito psicólogas que, entre junho e novembro de 2012,
                            atenderam 24 pacientes: 12 deles via Skype e 12 em sessões presenciais. Dois anos depois, os dados foram comparados e constatou que a terapia online nada ficou a dever ao modelo convencional. </p>

                        <h4>Atendimento mais barato</h4>

                        <p>Desta forma, a psicoterapia online proporciona ao paciente os mesmos objetivos que a presencial. A única diferença é que este é feito por um dispositivo tecnológico (celular, tablete ou computador). Esta modalidade é somente uma
                            forma de facilitar o acesso ao serviço, pois este se torna mais acessível, mais econômico (paciente e profissional não precisam se deslocar), mais barato (consultas online dispensam vários custos), geram mais tempo livre, comodidade
                            (o atendimento pode ocorrer em qualquer lugar), e são indicadas para quem não pode sair de casa por alguma dificuldade (como fobias, traumas ou limitações físicas) e em tempos de pandemia, dispensa o contato físico.</p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art5_01.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Créditos: Freepik</small></figcaption>
                            </figure>
                        </div>

                        <h4>E a relação paciente e o profissional, como fica?</h4>

                        <figure style="float: left; width: 300px; margin-right: 30px; margin-bottom: 10px;">
                            <img src="assets/images/blog/art5_03.jpg" alt="">
                        </figure>
                        <p>A mesma que a presencial, pois esta depende das características pessoais do paciente e do profissional. Por este motivo é muito importante escolher um profissional com o qual você se identifique pela sua linha de abordagem. </p>
                        <p>Nós da Listen acreditamos que seja uma questão de conhecer a modalidade e se adaptar e, para sermos sinceros, chega um momento em que você esquece que o profissional não está presencialmente à sua frente. A relação paciente e profissional,
                            depois de um tempo, torna-se natural. </p>
                        <p>A psicoterapia vai lhe proporcionar o encontro com um propósito de vida, aliviar suas tensões diárias provenientes de conflitos ou situações ruins, além de devolver sua confiança e ajudar em novos desafios. </p>
                        <p><a routerLink="/pesquisa" class="box-btn mt-3" style="width: 100%">Encontre um Profissional</a></p>
                        <hr>
                        <h4>Lembre-se de que você não está sozinha (o)!</h4>
                        <p>Agende uma consulta com nossos profissionais e cuide de sua saúde mental!</p>

                        <hr>
                        <p><i><b>Atenção</b>: "Se um profissional for procurado para atendimento online e avaliar que se trata de um caso de urgência e emergência, deverá fazer contato com a rede de assistência mais próxima ao usuário e encaminhá-lo para o serviço presencial."</i></p>

                        <p class="mt-5"><small><b>REFERÊNCIAS:</b></small></p>
                        <p class="reference">
                            Bernardo, André; Terapia online: os benefícios e os cuidados dessa nova tendência; Veja Saúde, Dez./2018.</p>
                        <p class="reference">
                            Conselho Federal de Psicologia; https://site.cfp.org.br/ Acesso em jan.2021.
                        </p>

                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a>Compartilhe</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/intent/tweet?url=https://www.listenonline.com.br/blog/{{ article_slug }}&text=" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.listenonline.com.br/blog/{{ article_slug }}&title=&summary=&source=" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="https://pinterest.com/pin/create/button/?url=https://www.listenonline.com.br/blog/{{ article_slug }}&media=&description=" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                <li><a href="mailto:info@example.com?&subject=&body=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bx-envelope'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog"><i class='bx bx-left-arrow-alt'></i> Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <app-blog-outros-artigos [article_id]="article_id"></app-blog-outros-artigos>
                    <app-blog-tags></app-blog-tags>
                    <app-blog-cta></app-blog-cta>
                </aside>
            </div>
        </div>
    </div>
</section>
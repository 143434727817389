import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-blog-details-05',
    templateUrl: './blog-details-05.component.html',
    styleUrls: ['./blog-details-05.component.scss']
})
export class BlogDetails05Component implements OnInit {
    public article_id;
    public article_slug;

    constructor(private titleService: Title,
        private metaTagService: Meta) {
        this.createTags();
        this.article_id = '5';
        this.article_slug = 'atendimento-online-e-permitido';
    }

    ngOnInit(): void {
    }

    createTags() {
        this.titleService.setTitle('Atendimento online é permitido?  | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({ name: 'description', content: 'Para prestar serviços psicológicos online é obrigatório o cadastro no e-Psi. O e-Psi lista as (os) profissionais que estão autorizadas (os) pelo Sistema Conselhos de Psicologia a prestarem serviços psicológicos on-line. Se a (o) profissional não estiver listada (o), ela (e) não está autorizada (o) a prestar esse serviço.' });
        this.metaTagService.updateTag({ name: 'robots', content: 'index, follow' });
        this.metaTagService.updateTag({ name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' });
        this.metaTagService.updateTag({ name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' });

        this.metaTagService.updateTag({ property: 'og:locale', content: "pt_BR" });
        this.metaTagService.updateTag({ property: 'og:type', content: "article" });
        this.metaTagService.updateTag({ property: 'og:title', content: 'Atendimento online é permitido?  | Listen - Estamos aqui para ouvir você' });
        this.metaTagService.updateTag({ property: 'og:description', content: '' });
        this.metaTagService.updateTag({ property: 'og:site_name', content: "Listen Online" });
        this.metaTagService.updateTag({ property: 'og:url', content: window.location.href });

        this.metaTagService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.metaTagService.updateTag({ name: 'twitter:title', content: 'Atendimento online é permitido?  | Listen - Estamos aqui para ouvir você' });
        this.metaTagService.updateTag({ name: 'twitter:description', content: '' });
        this.metaTagService.updateTag({ name: 'twitter:url', content: window.location.href });
    }

}

import {Component, OnInit} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';

@Component({
    selector: 'app-blog-details-04',
    templateUrl: './blog-details-04.component.html',
    styleUrls: ['./blog-details-04.component.scss']
})
export class BlogDetails04Component implements OnInit {
    public article_id;
    public article_slug;

    constructor(private titleService: Title,
        private metaTagService: Meta) {
        this.createTags();
        this.article_id = '4';
        this.article_slug = 'o-conto-da-bela-adormecida';
    }

    ngOnInit(): void {
    }
    
    createTags() {
        this.titleService.setTitle('O conto da Bela Adormecida: história infantil ou uma boa metáfora sobre Violência Contra a Mulher? | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({name: 'description', content: 'Bela Adormecida é um conto que tem várias versões, desde a famosa história criada pelos irmãos Grimm aos populares filmes da Disney. Em todas as versões a história tem a mesma base: a bela filha de um rei, depois de certa idade, cai em um sono profundo ao se ferir com uma máquina de fiar… Mas, a história original não é tão fofinha quanto à mostrada nos filmes.'});
        this.metaTagService.updateTag({name: 'robots', content: 'index, follow'});
        this.metaTagService.updateTag({name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});
        this.metaTagService.updateTag({name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});

        this.metaTagService.updateTag({property: 'og:locale', content: "pt_BR"});
        this.metaTagService.updateTag({property: 'og:type', content: "article"});
        this.metaTagService.updateTag({property: 'og:title', content: 'O conto da Bela Adormecida: história infantil ou uma boa metáfora sobre Violência Contra a Mulher? | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({property: 'og:description', content: ''});
        this.metaTagService.updateTag({property: 'og:site_name', content: "Listen Online"});
        this.metaTagService.updateTag({property: 'og:url', content: window.location.href});

        this.metaTagService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.metaTagService.updateTag({name: 'twitter:title', content: 'O conto da Bela Adormecida: história infantil ou uma boa metáfora sobre Violência Contra a Mulher? | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({name: 'twitter:description', content: ''});
        this.metaTagService.updateTag({name: 'twitter:url', content: window.location.href});
    }

}

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>A psicologia é importante?</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/blog">Blog </a></li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100 pt-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <!--div class="article-image">
                        <img src="assets/images/blog-details/blog-details.jpg" alt="">
                    </div-->

                    <div class="article-content">
                        <h3 style="margin-top: 0px;">A psicologia é importante?</h3>

                        <div class="entry-meta mb-20">
                            <ul>
                                <li><span>Publicado em:</span>&nbsp;11 de Janeiro de 2020</li>
                                <li><span>Por:</span>&nbsp;<a routerLink="/sobre">Equipe Listen</a></li>
                            </ul>
                        </div>

                        <figure style="float: left; width: 220px; margin-right: 30px; margin-bottom: 10px;">
                            <img src="assets/images/blog/art1_01.jpg" alt="">
                            <figcaption style="text-align: center;"><small>Créditos: Pexels</small></figcaption>
                        </figure>
                        <p>Sim, pois ela é a ciência responsável pelo estudo do comportamento das pessoas, do indivíduo, e, sobretudo, porque a Psicologia vem ocupando um espaço de destaque na sociedade. A psicologia teve grandes avanços e fornece a compreensão
                            das relações humanas baseadas em metodologias que abrangem campos como clínicos, sociais, trabalhistas e educacionais, proporcionando assim uma solução baseada no desenvolvimento e bem-estar do indivíduo.</p>
                        <p>Existem diferentes situações que podem provocar uma mudança no emocional, seja por traumas, problemas cotidianos ou a vontade de melhorar de vida. Estas situações fazem com que o psicólogo seja importante na vida dos indivíduos
                            que buscam uma boa saúde mental e qualidade de vida. Muitos indivíduos não se sentem à vontade em confiar seus sentimentos e questões particulares a outra pessoa. Desta forma, os psicólogos são profissionais com uma escuta
                            qualificada, construída com base em anos de estudo, preparados para analisar e apresentar alternativas.</p>
                        <div class="mb-3 mt-3">
                            <figure>
                                <img src="assets/images/blog/art1_02.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Créditos: Freepik</small></figcaption>
                            </figure>
                        </div>
                        <p>Os psicólogos recebem uma formação completa em diferentes situações para poder ajudar o indivíduo a solucionar seus problemas, motivo pelo qual buscam esse profissional. O profissional de psicologia fará um estudo minucioso sobre
                            a questão emocional, de comportamento, de sofrimento, entre outros fatores que eventualmente podem aparecer, proporcionando desta forma que o indivíduo consiga acessar seus problemas internos de maneira a trabalhar em conjunto
                            e assim poder alcançar o objetivo do bem-estar psicológico e sua qualidade de vida.</p>
                        <p>A Listen promove através de sua plataforma de atendimento psicológico pela internet um atendimento humanizado, pois ele é essencial tanto para pacientes quanto para os profissionais de psicologia. Por isso, o papel da psicologia
                            se mostra tão importante no momento em que atualmente nos encontramos: uma pandemia.</p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art1_03.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Créditos: Pexels</small></figcaption>
                            </figure>
                        </div>
                        <p>A terapia online é uma alternativa rápida e segura de consultar os melhores profissionais de psicologia. Conheça as vantagens desta modalidade e encontre o profissional ideal para você.</p>
                        <ul class="article-ul mb-3">
                            <li><b>Economia</b>: profissional e paciente não precisa se deslocar;</li>
                            <li><b>Contato físico</b>: não há preocupação com contato físico;</li>
                            <li><b>Consultas mais baratas</b>: as consultas online dispensam vários custos, tanto para o profissional como para o paciente;</li>
                            <li><b>Mais tempo livre</b>: profissional e paciente aproveitam melhor seu tempo;</li>
                            <li><b>Proximidade</b>: a terapia online proporciona a mesma eficiência das consultas presenciais.</li>
                        </ul>
                        <hr>
                        <p style="font-weight: bold; font-size: 120%">Agora que você entendeu a importância da Psicologia, que tal conhecer os profissionais da Listen Online e agendar um atendimento psicológico por videochamada?</p>
                        <p style="font-weight: bold; font-size: 120%">Agende sua consulta psicológica com segurança. </p>
                        <p><a routerLink="/pesquisa" class="box-btn mt-3">Encontre um Profissional</a></p>

                        <p class="mt-5"><small><b>REFERÊNCIAS:</b></small></p>
                        <p class="reference">
                            Ramos, Pedro; Por que a psicologia é tão importante; Afya, Educação, Tecnologia e Saúde, Agosto. 2019.
                        </p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a>Compartilhe</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/intent/tweet?url=https://www.listenonline.com.br/blog/{{ article_slug }}&text=" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.listenonline.com.br/blog/{{ article_slug }}&title=&summary=&source=" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="https://pinterest.com/pin/create/button/?url=https://www.listenonline.com.br/blog/{{ article_slug }}&media=&description=" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                <li><a href="mailto:info@example.com?&subject=&body=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bx-envelope'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog"><i class='bx bx-left-arrow-alt'></i> Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <app-blog-outros-artigos [article_id]="article_id"></app-blog-outros-artigos>
                    <app-blog-tags></app-blog-tags>
                    <app-blog-cta></app-blog-cta>
                </aside>
            </div>
        </div>
    </div>
</section>
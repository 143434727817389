<section class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Atenda seus clientes on-line, de onde estiver</h1>
                            <p>Temos um ambiente seguro para que você atenda seus clientes por videochamada, com um escritório virtual completo à sua disposição.</p>
                            <div class="banner-btn">
                                <a routerLink="/profissional/inscreva-se" class="box-btn">Inscreva-se</a>
                                <a routerLink="/profissional/como-funciona" class="box-btn border-btn">Como Funciona</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                            <img src="assets/images/home-bg-2-img.png" alt="banner-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Vantagens</span>
            <h2>Amplie sua carteira de clientes</h2>
            <p> O atendimento on-line equivale à terapia presencial e é aprovado pelo Conselho Federal de Psicologia (CFP). Saiba por que somos a melhor opção para você.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Conectividade</h3>
                        <p>Você atende seus clientes pela internet, de onde estiver.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Flexibilidade</h3>
                        <p>Você organiza sua agenda conforme sua disponibilidade de horários.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Segurança</h3>
                        <p>Todas as consultas são realizadas dentro da plataforma, com total sigilo. Após estar logado, basta clicar no horário programado na sua agenda para começar o atendimento.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Cadastro Rápido</h3>
                        <p>Após preencher o cadastro com seus dados e enviar seus documentos, ele é aprovado em até 24 horas.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Na Palma da Mão</h3>
                        <p>Você mesmo controla suas consultas, horários e valores a receber, gerenciados eletronicamente pelo sistema Safe2Pay.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Visibilidade</h3>
                        <p>Seus serviços ficarão à disposição de pessoas do mundo inteiro, aumentando sua base de clientes.</p>
                        <!--a routerLink="/solutions-details" class="line-bnt">Read More</a-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Quem somos</span>
                        <h2>Nossa missão é conectar você aos seus clientes</h2>
                        <p>O atendimento psicológico on-line é uma nova oportunidade de mercado para os profissionais de psicologia. Pensando nisso, a Listen foi planejada para oferecer, de forma segura e prática, uma plataforma completa para que os profissionais ingressem neste mercado, atendendo seus clientes com base em um sistema confiável e seguro, no qual ele mantém total controle sobre seus horários, mantém contato com seus clientes e gerencia os recursos que tem a receber.</p>
                        <p>Somos uma empresa idônea e seguimos todas as exigências legais no que se refere a segurança eletrônica e critérios dos órgãos de psicologia. Somos a oportunidade ideal para o seu crescimento profissional.</p>
                    </div>

                    <a routerLink="/sobre" class="box-btn">Sobre a Listen</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img5.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>
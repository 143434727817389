<section class="widget widget-peru-posts-thumb" style="margin-top: 35px;">
    <div class="post-wrap">
        <img src="assets/images/blog/cta_01.jpg" alt="">
        <div class="advertise mt-3">
            <p>
                A Listen oferece uma opção diferenciada de atendimento com profissionais de psicologia, com consultas por videochamada. Eles oferecem soluções para gerar os melhores resultados pessoais e profissionais.
            </p>
            <p>
                Agende sua consulta com um de nossos profissionais.
            </p>
            <ul class="article-ul">
                <li>Você pode realizar consultas com psicólogos pela internet, de onde você estiver.</li>
                <li>Você mesmo programa seus horários conforme sua disponibilidade e a agenda do profissional, adaptando suas consultas ao seu ritmo de atividade.</li>
                <li>Você paga pelos serviços conforme utilizá-los, tudo de forma segura, através da plataforma, contando com várias formas de pagamento.</li>
            </ul>
            <p><a routerLink="/pesquisa" class="box-btn mt-3" style="width: 100%">Encontre um Profissional</a></p>
        </div>
    </div>
</section>
import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AllServices} from 'src/app/services/all.service';
import {Options} from '@angular-slider/ngx-slider';
import {format, add} from 'date-fns';
import {zonedTimeToUtc, utcToZonedTime} from 'date-fns-tz'
import {Router} from '@angular/router';
import {Portal} from '@angular/cdk/portal';
import {environment} from 'src/environments/environment';
import {User} from 'src/app/model/User';
import {PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmScheduleDialogComponent} from '../confirm-schedule/confirm-schedule.component';

declare const ModalVideo: any;

@Component({
    selector: 'app-professional-details',
    templateUrl: './professional-details.component.html',
    styleUrls: ['./professional-details.component.scss']
})

export class ProfessionalDetailsComponent implements OnInit, AfterViewInit {

    pattern = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"

    sending: false;
    days = [];

    today = new Date();

    @Input() pro: any;
    @Input() mode: string;

    selectedTime;
    selectedProfessional;

    searching = false;
    searched = false;

    modalvideo: any;

    minValue: number = 50;
    maxValue: number = 450;
    options: Options = {
        floor: 0,
        ceil: 500,
        getPointerColor: (value: number): string => {
            return '#5f9034';
        },
        getSelectionBarColor: (value: number): string => {
            return '#5f9034';
        }
    };


    dialogRef: any;

    constructor(private allServices: AllServices,
        private router: Router,
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog, ) {}

    ngOnInit(): void {
        this.days = this.getDays(this.today);
    }

    ngAfterViewInit() {
        this.modalvideo = new ModalVideo(".js-video-button-" + this.pro.id, {youtube: {autoplay: 1}});
    }

    showVideo() {
        this.modalvideo(".js-video-button-" + this.pro.id, {youtube: {autoplay: 1}});
    }

    showDetails(pro) {
        this.allServices.selectedProfessional = pro;
        //this.router.navigate(['/profissional/' + pro.slug]);
        this.router.navigate([]).then(result => {window.open(environment.site_url + 'profissional/' + pro.slug, '_blank');});
    }

    markDay(time, professional) {
        this.selectedTime = time;
        this.selectedProfessional = professional;
        
        this.confirmAppointment(time, professional);
    }

    itsTime(time, professional) {
        if (this.selectedTime == time && this.selectedProfessional == professional) {
            return true;
        }
        return false;
    }

    callRegister() {
        let data = {professional_id: this.selectedProfessional.id, time: format(this.selectedTime, this.pattern), name: this.selectedProfessional.name};
        this.router.navigate(['para-voce/cadastre-se', data]);
    }

    callLogin() {
        let data = {professional_id: this.selectedProfessional.id, time: format(this.selectedTime, this.pattern)};
        window.location.href = environment.app_url + "pages/auth/login?data=" + btoa(JSON.stringify(data));
    }

    previousWeek(professional) {
        let week = add(professional.days[0], {weeks: -1})
        this.getAvailibilities(professional, week);
    }

    nextWeek(professional) {
        let week = add(professional.days[0], {weeks: 1})
        this.getAvailibilities(professional, week)
    }

    getAvailibilities(professional, date) {
        const output = format(date, this.pattern)
        let days = this.getDays(date);

        this.allServices.getProfessionalAvailibilities(professional.professional_id, output).subscribe(data => {
            professional.availabilities = data.availabilities;
            this.updateProTimes(professional, days);
        });
    }

    getDays(start) {
        let days = [];
        for (let i = 0; i < 7; i++) {
            days.push(add(start, {days: i}));
        }
        return days;
    }

    checkDate(date) {
        if (add(date, {days: -1}) >= this.today) {
            return true;
        } else {
            return false;
        }
    }

    updateProTimes(pro, days) {
        let pos = 0;
        let dates = [];
        pro.screenAvailableTimes = [];
        pro.days = days;
        for (let av of pro.availabilities) {
            const utcDate = zonedTimeToUtc(av.datetime, pro.timezone);
            // TODO - converter para o timezone do usuario
            if (utcDate.getDay() == days[pos].getDay()) {
                dates.push(utcDate);
            } else {
                pro.screenAvailableTimes.push({date: days[pos], times: dates});
                while (pos < days.length) {
                    if (utcDate.getDay() == days[pos].getDay()) {
                        dates = [];
                        dates.push(utcDate);
                        break;
                    } else {
                        pos = pos + 1;
                    }
                }
            }
        }
        if (dates.length > 0) {
            pro.screenAvailableTimes.push({date: days[pos], times: dates});
        }
    }

    confirmAppointment(time, professional) {
        this.dialogRef = this._matDialog.open(ConfirmScheduleDialogComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: 'save',
                time: time,
                professional: professional 
            }
        });

        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result == 'login') {
                    this.callLogin();
                }
                if (result == 'register') {
                    this.callRegister();
                }
            }
            this.dialogRef = null;
        });
    }

    toggleAgenda(id) {
        var x = document.getElementById("agenda-" + id);
        if (x.style.display === "none") {
            x.style.display = "block";
            document.getElementById("btn-show-" + id).style.display = "none";
            document.getElementById("btn-hide-" + id).style.display = "inline-block";
        } else {
            x.style.display = "none";
            document.getElementById("btn-show-" + id).style.display = "inline-block";
            document.getElementById("btn-hide-" + id).style.display = "none";
        }
    }

}

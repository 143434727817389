<section class="widget widget-peru-posts-thumb">
    <h3 class="widget-title">Dicas de Leitura</h3>

    <div class="post-wrap">
        <article class="item" *ngIf="article_id != '8'">
            <a routerLink="/blog/o-que-e-anamnese" class="thumb"><span class="fullimage cover bg8" role="img"></span></a>
            <div class="info">
                <time datetime="2020-06-30">18 de Janeiro de 2021</time>
                <h4 class="title usmall"><a routerLink="/blog/o-que-e-anamnese">O que é anamnese?</a></h4>
            </div>
            <div class="clear"></div>
        </article>

        <article class="item" *ngIf="article_id != '7'">
            <a routerLink="/blog/tempestade-emocional" class="thumb"><span class="fullimage cover bg7" role="img"></span></a>
            <div class="info">
                <time datetime="2020-06-30">18 de Janeiro de 2021</time>
                <h4 class="title usmall"><a routerLink="/blog/tempestade-emocional">Tempestade emocional</a></h4>
            </div>
            <div class="clear"></div>
        </article>

        <article class="item" *ngIf="article_id != '6'">
            <a routerLink="/blog/o-trabalho-esta-te-matando" class="thumb"><span class="fullimage cover bg6" role="img"></span></a>
            <div class="info">
                <time datetime="2020-06-30">15 de Janeiro de 2021</time>
                <h4 class="title usmall"><a routerLink="/blog/o-trabalho-esta-te-matando">O trabalho está te matando?</a></h4>
            </div>
            <div class="clear"></div>
        </article>

        <article class="item" *ngIf="article_id != '5'">
            <a routerLink="/blog/atendimento-online-e-permitido" class="thumb"><span class="fullimage cover bg5" role="img"></span></a>
            <div class="info">
                <time datetime="2020-06-30">15 de Janeiro de 2021</time>
                <h4 class="title usmall"><a routerLink="/blog/atendimento-online-e-permitido">O atendimento online é permitido?</a></h4>
            </div>
            <div class="clear"></div>
        </article>

        <article class="item" *ngIf="article_id != '4'">
            <a routerLink="/blog/o-conto-da-bela-adormecida" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
            <div class="info">
                <time datetime="2020-06-30">13 de Janeiro de 2021</time>
                <h4 class="title usmall"><a routerLink="/blog/o-conto-da-bela-adormecida">O conto da Bela Adormecida</a></h4>
            </div>
            <div class="clear"></div>
        </article>

        <article class="item" *ngIf="article_id != '3'">
            <a routerLink="/blog/saude-mental" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
            <div class="info">
                <time datetime="2020-06-30">13 de Janeiro de 2021</time>
                <h4 class="title usmall"><a routerLink="/blog/saude-mental">Saúde mental</a> </h4>
            </div>
            <div class="clear"></div>
        </article>

        <article class="item" *ngIf="article_id != '2'">
            <a routerLink="/blog/qual-e-o-papel-do-psicologo" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
            <div class="info">
                <time datetime="2020-06-30">12 de Janeiro de 2021</time>
                <h4 class="title usmall"><a routerLink="/blog/qual-e-o-papel-do-psicologo">Qual é o papel do psicólogo?</a></h4>
            </div>
            <div class="clear"></div>
        </article>

        <article class="item" *ngIf="article_id != '1'">
            <a routerLink="/blog/a-psicologia-e-importante" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
            <div class="info">
                <time datetime="2020-06-30">11 de Janeiro de 2021</time>
                <h4 class="title usmall"><a routerLink="/blog/a-psicologia-e-importante">A psicologia é importante?</a></h4>
            </div>
            <div class="clear"></div>
        </article>
    </div>
</section>
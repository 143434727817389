<section class="home-contact-area home-2-contact pb-5 m-0 mt-3">
    <div class="content">
        <form id="professionalForm" [formGroup]="professionalForm">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <span>Nome do profissional</span>
                        <input type="text" formControlName='name' name="name" id="name" class="form-control" placeholder="" />
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <span>Especialidades</span>
                        <mat-select class="form-control" multiple formControlName='specialties'>
                            <mat-option *ngFor='let data of specialties' value={{data.id}}>{{data.name}}</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <span>Público-alvo</span>
                        <mat-select class="form-control" multiple formControlName='audiences'>
                            <mat-option *ngFor='let data of audiences' value={{data.id}}>{{data.name}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <span>Gênero do profissional</span>
                        <mat-select class="form-control" formControlName='gender'>
                            <mat-option value='M'>Masculino</mat-option>
                            <mat-option value='F'>Feminino</mat-option>
                            <mat-option value=''></mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                        <span>Valor da consulta</span>
                        <ngx-slider formControlName='appointment_value' [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4">
                    <div class="form-group">
                        <span>Consultas a partir de<br></span>
                        <div class='date-picker'>
                            <input matInput [matDatepicker]="picker" class="form-control date-form" formControlName='start_date' [min]='today'>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-4">
                    <div class="form-group">
                        <span>Ordenar pesquisa por<br></span>
                        <mat-select class="form-control" formControlName='order'>
                            <mat-option value='ANY'></mat-option>
                            <mat-option value='VA'>Valor (menor para maior)</mat-option>
                            <mat-option value='VD'>Valor (maior para menor</mat-option>
                            <mat-option value='A'>Alfabética</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="form-group">
                        <button type="submit" class="default-btn page-btn box-btn search-btn" [disabled]='searching' (click)='searchUsers()'><i class="fa fa-search"></i> Buscar profissionais</button>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="form-group">
                        <button class="default-btn page-btn box-btn  search-btn btn-inverted" [disabled]='searching' (click)='clearSearch()'>Limpar Pesquisa</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>

<div class="row">
    <div class="col-lg-12 text-center warning" *ngIf='searched && professionals.length == 0'>
        <h5>Não encontramos nenhum profissional encontrado com os critérios de pesquisa informados. Por favor, tente novamente.</h5>
    </div>

    <div class="col-lg-12 m-0" *ngFor='let pro of pageProfessionals'>
        <app-professional-details [pro]='pro' mode='Simple'></app-professional-details>
    </div>

    <div *ngIf="searched && professionals.length>0" style="width: 100%">
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = changePage($event)"></mat-paginator>
    </div>
</div>
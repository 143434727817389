<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>O que é anamnese?</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/blog">Blog </a></li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100 pt-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <!--div class="article-image">
                        <img src="assets/images/blog-details/blog-details.jpg" alt="">
                    </div-->

                    <div class="article-content">
                        <h3 style="margin-top: 0px">O que é anamnese?</h3>

                        <div class="entry-meta mb-20">
                            <ul>
                                <li><span>Publicado em:</span>&nbsp;18 de Janeiro de 2020</li>
                                <li><span>Por:</span>&nbsp;<a routerLink="/sobre">Equipe Listen</a></li>
                            </ul>
                        </div>

                        <p>A anamnese é uma entrevista utilizada por psicólogos e médicos que possui técnicas para poder estabelecer uma avaliação e diagnóstico do indivíduo. É a base a partir da qual se pode estabelecer um tratamento ou iniciar uma psicoterapia
                            de apoio ou outra qualquer, um instrumento muito importante para o início das intervenções psicológicas e para o profissional estruturar seus atendimentos posteriores.</p>
                        <h4>Como ela é feita?</h4>
                        <p>A anamnese é usada para conhecer melhor o paciente através do levantamento de sua história de vida, suas maiores queixas, histórico de doenças, dinâmica familiar, social, de trabalho etc. Assim, o psicólogo consegue ter uma melhor
                            compreensão do paciente e formular uma melhor estratégia para o acompanhamento do caso.</p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art8_01.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Anamnese é imortante para definir uma estratégia de acompanhamento do paciente.<br>Créditos: Pexels</small></figcaption>
                            </figure>
                        </div>

                        <p>O roteiro da anamnese é uma forma padronizada de registrar a entrevista. Para a realização de uma boa anamnese, acima de tudo é necessário saber ouvir. Ao iniciar a entrevista com uma pergunta aberta, é importante que o(a) profissional
                            dê tempo para o paciente responder.</p>

                        <h4>Pontos abordados</h4>
                        <p>A anamnese tradicional é composta por 7 itens:</p>
                        <ol class="article-ol mb-3">
                            <li>Identificação;</li>
                            <li>Queixa Principal (QP);</li>
                            <li>História da Moléstia Atual (HMA);</li>
                            <li>Revisão de Sistemas;</li>
                            <li>História Patológica Pregressa;</li>
                            <li>História Familiar;</li>
                            <li>História Social/ Hábitos de vida.</li>
                        </ol>
                        <p>Quando é solicitado atendimento para crianças, a anamnese psicológica é realizada com os pais ou cuidadores. A quantidade de sessões com os pais é determinada pelo(a) profissional, e somente depois a criança será atendida.</p>
                        <p>Algo muito importante é que na anamnese deve ser levada em conta não só a fala do paciente, mas também deve ser observada a linguagem corporal e os aspectos emocionais envolvidos. A anamnese psicológica também é o momento para
                            o estabelecimento de um vínculo entre profissional e paciente/cliente; é um espaço de acolhimento. Cada Psicólogo possui uma maneira de trabalhar com a anamnese, porém, sem perder os conceitos básicos de sua estrutura.</p>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art8_02.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Linguagem corporal deve ser analisada na anamnese.<br>Créditos: Pexels</small></figcaption>
                            </figure>
                        </div>

                        <h4>A primeira sessão (ou anamnese) é paga? </h4>
                        <figure style="float: left; width: 300px; margin-right: 30px; margin-bottom: 10px;">
                            <img src="assets/images/blog/art8_03.png" alt="">
                        </figure>

                        <p>Depende do(a) profissional. Alguns preferem realizar a anamnese sem custo. Caso seja paga a primeira sessão, o(a) psicólogo(a) deverá disponibiliza seu tempo e experiência profissional para acolher e orientar o cliente.</p>
                        <p>Nós da Listen estamos aqui para ouvir você, para buscar a compreensão de sua história, lhe ajudar a se descobrir, a compreender suas dificuldades e promover a sua autonomia, proporcionando o auxílio na construção de estratégias
                            defensivas para mediar o sofrimento e ressignificá-lo.</p>
                        <p><a routerLink="/pesquisa" class="box-btn mt-3" style="width: 100%">Encontre um Profissional</a></p>

                        <p class="mt-5"><small><b>REFERÊNCIAS:</b></small></p>
                        <p class="reference">
                            Semiologia Médica UFOP; A Consulta; Universidade Federal de Ouro Preto.
                        </p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a>Compartilhe</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/intent/tweet?url=https://www.listenonline.com.br/blog/{{ article_slug }}&text=" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.listenonline.com.br/blog/{{ article_slug }}&title=&summary=&source=" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="https://pinterest.com/pin/create/button/?url=https://www.listenonline.com.br/blog/{{ article_slug }}&media=&description=" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                <li><a href="mailto:info@example.com?&subject=&body=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bx-envelope'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog"><i class='bx bx-left-arrow-alt'></i> Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <app-blog-outros-artigos [article_id]="article_id"></app-blog-outros-artigos>
                    <app-blog-tags></app-blog-tags>
                    <app-blog-cta></app-blog-cta>
                </aside>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-blog-outros-artigos',
    templateUrl: './blog-outros-artigos.component.html',
    styleUrls: ['./blog-outros-artigos.component.scss']
})
export class BlogOutrosArtigosComponent implements OnInit {
    @Input() article_id: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AllServices } from 'src/app/services/all.service';
import { Options } from '@angular-slider/ngx-slider';
import { format, add } from 'date-fns';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-pesquisa',
    templateUrl: './pesquisa.component.html',
    styleUrls: ['./pesquisa.component.scss']
})
export class PesquisaComponent implements OnInit {

    constructor(private titleService: Title,
        private metaTagService: Meta) { }

    ngOnInit(): void {
        this.createTags();
    }

    createTags() {
        this.titleService.setTitle('Para Você > Encontre um profissional | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({ name: 'description', content: 'Encontre o profissional certo para você.' });
        this.metaTagService.updateTag({ name: 'robots', content: 'index, follow' });
        this.metaTagService.updateTag({ name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' });
        this.metaTagService.updateTag({ name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' });

        this.metaTagService.updateTag({ property: 'og:locale', content: "pt_BR" });
        this.metaTagService.updateTag({ property: 'og:type', content: "article" });
        this.metaTagService.updateTag({ property: 'og:title', content: 'Para Você > Encontre um profissional | Listen - Estamos aqui para ouvir você' });
        this.metaTagService.updateTag({ property: 'og:description', content: '' });
        this.metaTagService.updateTag({ property: 'og:site_name', content: "Listen Online" });
        this.metaTagService.updateTag({ property: 'og:url', content: window.location.href });

        this.metaTagService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.metaTagService.updateTag({ name: 'twitter:title', content: 'Para Você > Encontre um profissional | Listen - Estamos aqui para ouvir você' });
        this.metaTagService.updateTag({ name: 'twitter:description', content: '' });
        this.metaTagService.updateTag({ name: 'twitter:url', content: window.location.href });
    }

}

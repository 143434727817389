import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AllServices} from 'src/app/services/all.service';
import {ValidateBrService} from 'angular-validate-br';

@Component({
  selector: 'app-para-voce',
  templateUrl: './para-voce.component.html',
  styleUrls: ['./para-voce.component.scss']
})
export class ParaVoceComponent implements OnInit {

    subscribeForm: FormGroup;
    sending = false;

    constructor(private allServices: AllServices,
        private _formBuilder: FormBuilder,
        private toast: ToastrService,
        private validateBrService: ValidateBrService,
        private router: Router,
        private titleService: Title,
        private metaTagService: Meta) {
            this.createTags();
        }

    ngOnInit(): void {
        this.subscribeForm = this.createSubscribeForm();
    }
    
    createSubscribeForm(): FormGroup {
        return this._formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]]
        });
    }

    sendSubscribeForm() {
        if (this.subscribeForm.invalid) {
            this.toast.error('Formulário incompleto!', 'Por favor, preencha o formulário com todos os campos obrigatórios!')
        } else {
            this.sending = true
            this.allServices.postSubscribe(this.subscribeForm.value).subscribe(data => {
                this.subscribeForm = this.createSubscribeForm();
                this.toast.success('Alerta criado com sucesso!', 'Sucesso!');
                this.sending = false;
            }, error => {
                this.toast.error(error.error.message, 'Erro!');
                this.sending = false;
            })
        }
    }

    createTags() {
        this.titleService.setTitle('Para Você > O que é | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({name: 'description', content: 'Nosso maior objetivo é atender às suas necessidades, oferecendo-lhe opções de atendimento com profissionais especializados, que lhe oferecerão soluções para gerar os melhores resultados pessoais e profissionais.'});
        this.metaTagService.updateTag({name: 'robots', content: 'index, follow'});
        this.metaTagService.updateTag({name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});
        this.metaTagService.updateTag({name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});

        this.metaTagService.updateTag({property: 'og:locale', content: "pt_BR"});
        this.metaTagService.updateTag({property: 'og:type', content: "article"});
        this.metaTagService.updateTag({property: 'og:title', content: 'Para Você > O que é | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({property: 'og:description', content: ''});
        this.metaTagService.updateTag({property: 'og:site_name', content: "Listen Online"});
        this.metaTagService.updateTag({property: 'og:url', content: window.location.href});

        this.metaTagService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.metaTagService.updateTag({name: 'twitter:title', content: 'Para Você > O que é | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({name: 'twitter:description', content: ''});
        this.metaTagService.updateTag({name: 'twitter:url', content: window.location.href});
    }

}

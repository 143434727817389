import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-blog-tags',
    templateUrl: './blog-tags.component.html',
    styleUrls: ['./blog-tags.component.scss']
})
export class BlogTagsComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}

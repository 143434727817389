import {Component, OnInit} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';

@Component({
    selector: 'app-blog-details-03',
    templateUrl: './blog-details-03.component.html',
    styleUrls: ['./blog-details-03.component.scss']
})
export class BlogDetails03Component implements OnInit {
    public article_id;
    public article_slug;

    constructor(private titleService: Title,
        private metaTagService: Meta) {
        this.createTags();
        this.article_id = '3';
        this.article_slug = 'saude-mental';
    }

    ngOnInit(): void {
    }
    
    createTags() {
        this.titleService.setTitle('Saúde mental | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({name: 'description', content: 'Saúde mental é um tema que merece atenção. Tanto que o mês de Janeiro foi escolhido para promover o “Janeiro Branco”, uma campanha que tem por objetivo mobilizar a sociedade em favor da saúde mental, mudando a compreensão cercada de tabus que existe sobre o tema e promovendo mais possibilidade de saúde mental a todos os indivíduos e à sociedade como um todo.'});
        this.metaTagService.updateTag({name: 'robots', content: 'index, follow'});
        this.metaTagService.updateTag({name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});
        this.metaTagService.updateTag({name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});

        this.metaTagService.updateTag({property: 'og:locale', content: "pt_BR"});
        this.metaTagService.updateTag({property: 'og:type', content: "article"});
        this.metaTagService.updateTag({property: 'og:title', content: 'Saúde mental | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({property: 'og:description', content: ''});
        this.metaTagService.updateTag({property: 'og:site_name', content: "Listen Online"});
        this.metaTagService.updateTag({property: 'og:url', content: window.location.href});

        this.metaTagService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.metaTagService.updateTag({name: 'twitter:title', content: 'Saúde mental | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({name: 'twitter:description', content: ''});
        this.metaTagService.updateTag({name: 'twitter:url', content: window.location.href});
    }

}

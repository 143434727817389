import {Component, OnInit} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';

@Component({
    selector: 'app-blog-details-02',
    templateUrl: './blog-details-02.component.html',
    styleUrls: ['./blog-details-02.component.scss']
})
export class BlogDetails02Component implements OnInit {
    public article_id;
    public article_slug;

    constructor(private titleService: Title,
        private metaTagService: Meta) {
        this.createTags();
        this.article_id = '2';
        this.article_slug = 'qual-e-o-papel-do-psicologo';
    }

    ngOnInit(): void {
    }
    
    createTags() {
        this.titleService.setTitle('Qual é o papel do psicólogo? | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({name: 'description', content: 'A psicologia oferece uma solução alternativa para os conflitos sociais, emocionais, individuais e elabora um saber sobre si mesmo, sobre o próprio mundo. O psicólogo tem como papel ajudar o paciente e, sobretudo, estruturar uma forma de relação da pessoa com seu meio, ajudá-la a configurar um mundo onde o indivíduo ocupa um lugar e assim materializar seus interesses sociais.'});
        this.metaTagService.updateTag({name: 'robots', content: 'index, follow'});
        this.metaTagService.updateTag({name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});
        this.metaTagService.updateTag({name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});

        this.metaTagService.updateTag({property: 'og:locale', content: "pt_BR"});
        this.metaTagService.updateTag({property: 'og:type', content: "article"});
        this.metaTagService.updateTag({property: 'og:title', content: 'Qual é o papel do psicólogo? | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({property: 'og:description', content: ''});
        this.metaTagService.updateTag({property: 'og:site_name', content: "Listen Online"});
        this.metaTagService.updateTag({property: 'og:url', content: window.location.href});

        this.metaTagService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.metaTagService.updateTag({name: 'twitter:title', content: 'Qual é o papel do psicólogo? | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({name: 'twitter:description', content: ''});
        this.metaTagService.updateTag({name: 'twitter:url', content: window.location.href});
    }

}

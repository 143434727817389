import {Component, OnInit} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';

@Component({
    selector: 'app-blog-details-01',
    templateUrl: './blog-details-01.component.html',
    styleUrls: ['./blog-details-01.component.scss']
})
export class BlogDetails01Component implements OnInit {

    public article_id;
    public article_slug;

    constructor(private titleService: Title,
        private metaTagService: Meta) {
        this.createTags();
        this.article_id = '1';
        this.article_slug = 'a-psicologia-e-importante';
    }

    ngOnInit(): void {
    }
    
    createTags() {
        this.titleService.setTitle('A psicologia é importante? | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({name: 'description', content: 'Sim, pois ela é a ciência responsável pelo estudo do comportamento das pessoas, do indivíduo, e, sobretudo, porque a Psicologia vem ocupando um espaço de destaque na sociedade. A psicologia teve grandes avanços e fornece a compreensão das relações humanas baseadas em metodologias que abrangem campos como clínicos, sociais, trabalhistas e educacionais, proporcionando assim uma solução baseada no desenvolvimento e bem-estar do indivíduo.'});
        this.metaTagService.updateTag({name: 'robots', content: 'index, follow'});
        this.metaTagService.updateTag({name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});
        this.metaTagService.updateTag({name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});

        this.metaTagService.updateTag({property: 'og:locale', content: "pt_BR"});
        this.metaTagService.updateTag({property: 'og:type', content: "article"});
        this.metaTagService.updateTag({property: 'og:title', content: 'A psicologia é importante? | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({property: 'og:description', content: ''});
        this.metaTagService.updateTag({property: 'og:site_name', content: "Listen Online"});
        this.metaTagService.updateTag({property: 'og:url', content: window.location.href});

        this.metaTagService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.metaTagService.updateTag({name: 'twitter:title', content: 'A psicologia é importante? | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({name: 'twitter:description', content: ''});
        this.metaTagService.updateTag({name: 'twitter:url', content: window.location.href});
    }

}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AllServices} from 'src/app/services/all.service';
import {Title, Meta} from '@angular/platform-browser';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-confirmar-cadastro',
    templateUrl: './confirmar-cadastro.component.html',
    styleUrls: ['./confirmar-cadastro.component.scss']
})
export class ConfirmarCadastroComponent implements OnInit {

    waiting = true;
    success = false;
    processed = false;
    type = 'PRO';

    constructor(private allServices: AllServices,
        private route: ActivatedRoute,
        private titleService: Title,
        private metaTagService: Meta) {
        this.createTags();
    }

    ngOnInit(): void {

        this.route.params.subscribe(params => {
            let id = params.id;
            this.allServices.activateUser(id).subscribe(data => {
                this.success = true;
                this.processed = true;
                this.waiting = false;
            }, error => {
                this.success = false;
                this.processed = true;
                this.waiting = false;
            })
        });
        let data = this.route.snapshot.queryParamMap.get('type');

        if (data) {
            this.type = data;
        }
    }

    callLogin() {
        window.location.href = environment.app_url + "pages/auth/login";
    }

    createTags() {
        this.titleService.setTitle('Confirmação de Cadastro | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({name: 'description', content: 'Ative sua conta e faça parte do universo Listen!'});
        this.metaTagService.updateTag({name: 'robots', content: 'index, follow'});
        this.metaTagService.updateTag({name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});
        this.metaTagService.updateTag({name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'});

        this.metaTagService.updateTag({property: 'og:locale', content: "pt_BR"});
        this.metaTagService.updateTag({property: 'og:type', content: "article"});
        this.metaTagService.updateTag({property: 'og:title', content: 'Confirmação de Cadastro | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({property: 'og:description', content: ''});
        this.metaTagService.updateTag({property: 'og:site_name', content: "Listen Online"});
        this.metaTagService.updateTag({property: 'og:url', content: window.location.href});

        this.metaTagService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.metaTagService.updateTag({name: 'twitter:title', content: 'Confirmação de Cadastro | Listen - Estamos aqui para ouvir você'});
        this.metaTagService.updateTag({name: 'twitter:description', content: ''});
        this.metaTagService.updateTag({name: 'twitter:url', content: window.location.href});
    }

}

import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-blog-details-07',
    templateUrl: './blog-details-07.component.html',
    styleUrls: ['./blog-details-07.component.scss']
})
export class BlogDetails07Component implements OnInit {
    public article_id;
    public article_slug;

    constructor(private titleService: Title,
        private metaTagService: Meta) {
        this.createTags();
        this.article_id = '7';
        this.article_slug = 'tempestade-emocional';
    }

    ngOnInit(): void {
    }

    createTags() {
        this.titleService.setTitle('Tempestade emocional: o que fazer com a "birra infantil"?  | Listen - Estamos aqui para ouvir você');
        this.metaTagService.updateTag({ name: 'description', content: 'Os pais, em geral, não conseguem avaliar este momento e só querem que a criança pare, mas o que precisamos entender é que as crianças estão em fase de desenvolvimento e cada fase nos traz algo novo e desafiador.' });
        this.metaTagService.updateTag({ name: 'robots', content: 'index, follow' });
        this.metaTagService.updateTag({ name: 'googlebot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' });
        this.metaTagService.updateTag({ name: 'bingbot', content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' });

        this.metaTagService.updateTag({ property: 'og:locale', content: "pt_BR" });
        this.metaTagService.updateTag({ property: 'og:type', content: "article" });
        this.metaTagService.updateTag({ property: 'og:title', content: 'Tempestade emocional: o que fazer com a "birra infantil"?  | Listen - Estamos aqui para ouvir você' });
        this.metaTagService.updateTag({ property: 'og:description', content: '' });
        this.metaTagService.updateTag({ property: 'og:site_name', content: "Listen Online" });
        this.metaTagService.updateTag({ property: 'og:url', content: window.location.href });

        this.metaTagService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.metaTagService.updateTag({ name: 'twitter:title', content: 'Tempestade emocional: o que fazer com a "birra infantil"?  | Listen - Estamos aqui para ouvir você' });
        this.metaTagService.updateTag({ name: 'twitter:description', content: '' });
        this.metaTagService.updateTag({ name: 'twitter:url', content: window.location.href });
    }
}

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Saúde mental</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li><a routerLink="/blog">Blog </a></li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100 pt-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <!--div class="article-image">
                        <img src="assets/images/blog-details/blog-details.jpg" alt="">
                    </div-->

                    <div class="article-content">
                        <h3 style="margin-top: 0px;">Saúde mental</h3>

                        <div class="entry-meta mb-20">
                            <ul>
                                <li><span>Publicado em:</span>&nbsp;13 de Janeiro de 2020</li>
                                <li><span>Por:</span>&nbsp;<a routerLink="/sobre">Equipe Listen</a></li>
                            </ul>
                        </div>

                        <div class="mb-3">
                            <img src="assets/images/blog/art3_01.jpg" alt="">
                        </div>
                        <p>Saúde mental é um tema que merece atenção. Tanto que o mês de Janeiro foi escolhido para promover o “Janeiro Branco”, uma campanha que tem por objetivo mobilizar a sociedade em favor da saúde mental, mudando a compreensão cercada
                            de tabus que existe sobre o tema e promovendo mais possibilidade de saúde mental a todos os indivíduos e à sociedade como um todo.</p>
                        <ul class="article-ul">
                            <li>Você já parou para pensar no assunto?</li>
                            <li>Já parou para avaliar se os seus pensamentos, ideias e sentimentos estão em harmonia?</li>
                            <li>Sabe a diferença entre saúde mental e doença ou transtorno mental?</li>
                        </ul>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art3_02.jpg" alt="">
                                <figcaption style="text-align: center;"><small>A saúde mental está associada à forma como uma pessoa reage às exigências, desafios e mudanças da vida e ao modo como harmoniza suas ideias e emoções.<br>Créditos: Freepik</small></figcaption>
                            </figure>
                        </div>
                        <p>Os temas “Saúde Mental” e “Transtorno Mental” causam algumas confusões. O primeiro refere-se à saúde e o outro à ausência dela. Não existe, porém, uma definição oficial para o conceito de saúde mental, de acordo com a Organização
                            Mundial de Saúde (OMS).</p>
                        <p>A saúde mental está associada à forma como uma pessoa reage às exigências, desafios e mudanças da vida e ao modo como harmoniza suas ideias e emoções. Diariamente, vivenciamos uma série de emoções, boas ou ruins, que fazem parte
                            da vida: alegria, felicidade, tristeza, raiva, frustação, satisfação, entre outras.</p>
                        <p>O desequilíbrio emocional facilita o surgimento de transtornos mentais. Podemos dizer que a saúde mental contempla, entre tantos fatores, a nossa capacidade de sensação de bem-estar e harmonia, a nossa habilidade em manejar de
                            forma positiva as adversidades e conflitos, o reconhecimento e o respeito aos nossos limites e deficiências, nossa satisfação em viver, compartilhar e se relacionar com os outros. </p>
                        <p>Manter a saúde mental, no entanto, não é tão simples quanto parece, principalmente nos dias de hoje. Estresse, brigas, atrasos, advertências, doenças, incapacidades, limitações, falta da família (ou excesso dela), pouco dinheiro
                            (ou muito dinheiro) são alguns dos fatores que podem influenciar negativamente a nossa saúde mental.</p>
                        <p>Os hábitos diários são determinantes para o alcance de resultados extraordinários no âmbito pessoal e profissional. São pequenas ações cotidianas que fazem uma diferença substancial para a concretização de suas metas e objetivos.
                            É preciso equilibrar nossa vida e nossos hábitos!</p>
                        <p>Busque ter comportamentos favoráveis, que te potencializem e colaborem para a construção de sua saúde mental. Seguem algumas dicas de hábitos favoráveis para uma vida melhor:</p>
                        <ul class="article-ul mb-3">
                            <li><b>Otimismo</b>: a força do pensamento é capaz de transformar nossa realidade, pois somos energia.</li>
                            <li><b>Não se isole demasiadamente</b>: nascemos para viver em sociedade, trocar experiências, nos comunicar e expressar ideias e opiniões.</li>
                            <li><b>Perdoe a si e aos outros</b>: o perdão é um ato nobre que apenas os humildes conseguem executar verdadeiramente.</li>
                            <li><b>Gestão do tempo</b>: o tempo é irreversível; portanto, aproveite-o bem, como se fosse a última oportunidade.</li>
                            <li><b>Busque novos conhecimentos</b>: a evolução é o maior sentido de nossa existência. Se paramos de aprender, deixamos de nos desenvolver e damos abertura à estagnação.</li>
                            <li><b>Não seja dependente de fatores externos</b>: você é o responsável por suas derrotas e vitórias, o único que possui o poder de mudar a própria realidade.</li>
                            <li><b>Busque seu equilíbrio</b>: por mais que a vida lhe traga desordens, mantenha-se forte, seja resiliente. O equilíbrio, muitas vezes, vem da flexibilidade. É preciso ter muita serenidade e sabedoria para manter-se em permanente
                                equilíbrio, pois muitas vezes todas as forças do universo parecem conspirar para nos fazer cair.</li>
                            <li><b>Tenha metas</b>: desenvolva o hábito de, todas as noites, fazer uma lista com suas metas para o próximo dia e cuide para executá-las.</li>
                            <li><b>Construa relacionamentos</b>: viver é também se relacionar com pessoas, e durante o relacionamento vamos adquirindo mais competências para lidar com cada uma delas. Isso traz felicidade para nossas vidas.</li>
                            <li><b>Trabalhe perdas e frustrações</b>: Todos nós procuramos aprender com os erros. Saber trabalhar as perdas e frustrações do dia a dia é dar um novo significado para a vida. Faça valer a sua história. Aprenda com seus erros
                                para se tornar um ser humano cada vez melhor.</li>
                            <li><b>Aceite as mudanças</b>: estamos o tempo todo em fase de mudanças. Infelizmente, nem todos os que o rodeiam estão preparados para mudar suas atitudes ou aceitar que você está mudando. Aceite as mudanças em você mesmo e nos
                                outros.
                            </li>
                            <li><b>Sinta prazer no que faz</b>: quando você gosta do que está fazendo e sente prazer, tudo fica mais gratificante, fica mais fácil e os resultados são melhores. Quando a pessoa está satisfeita, ela atrai prosperidade para si.
                                Por isso, avalie se o que você está vivendo hoje está valendo a pena.</li>
                            <li><b>Agradeça</b>: ser grato é uma das principais características das pessoas felizes; elas enxergam além do que está visível aos olhos e compreendem o poder na simplicidade de cada detalhe da vida cotidiana. O exercício da gratidão
                                é um estado de ser e elevação do EU maior. Agradecer é doar e receber, canalizar e direcionar as boas energias. </li>
                        </ul>
                        <div class="mb-3">
                            <figure>
                                <img src="assets/images/blog/art3_03.jpg" alt="">
                                <figcaption style="text-align: center;"><small>Créditos: Freepik</small></figcaption>
                            </figure>

                        </div>
                        <p>Comece a mudar seus hábitos hoje e sinta uma verdadeira transformação na sua vida, na sua saúde mental e em sua qualidade de vida. </p>
                        <p>O psicólogo pode te ajudar nessa transformação com avaliação e aplicabilidade no contexto profissional e pessoal, dando um direcionamento e maximizando seus resultados.</p>

                        <p class="mt-5"><small><b>REFERÊNCIAS:</b></small></p>
                        <p class="reference">
                            Einstein, Albert - Sociedade Beneficente Israelita Brasileira, Pare e olhe para você, 2020. / IBC – Instituto Brasilheiro de Coaching, 10 coisas que você não deve fazer para ter uma vida melhor, Set./2018.
                        </p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a>Compartilhe</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/intent/tweet?url=https://www.listenonline.com.br/blog/{{ article_slug }}&text=" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.listenonline.com.br/blog/{{ article_slug }}&title=&summary=&source=" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="https://pinterest.com/pin/create/button/?url=https://www.listenonline.com.br/blog/{{ article_slug }}&media=&description=" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                <li><a href="mailto:info@example.com?&subject=&body=https://www.listenonline.com.br/blog/{{ article_slug }}" target="_blank"><i class='bx bx-envelope'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog"><i class='bx bx-left-arrow-alt'></i> Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <app-blog-outros-artigos [article_id]="article_id"></app-blog-outros-artigos>
                    <app-blog-tags></app-blog-tags>
                    <app-blog-cta></app-blog-cta>
                </aside>
            </div>
        </div>
    </div>
</section>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pesquisa de Profissionais</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Pesquisa de Profissionais</li>
            </ul>
        </div>
    </div>

    <!--div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div-->
</div>

<div class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Encontre o profissional certo para você</h2>
            <p>Preencha os campos abaixo para filtrar e encontrar o profissional ideal para seu atendimento.</p>
        </div>
        <app-search></app-search>
    </div>
</div>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Blog</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Nosso Blog</span>
            <h2>Artigos</h2>
            <p>Uma seleção de artigos com o melhor conteúdo para você.</p>
        </div>

        <div class="row pt-70">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog/o-que-e-anamnese"><img src="assets/images/blog/art8_capa.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>18 de Janeiro de 2021</li>
                            <li>&nbsp;- Por Equipe Listen</li>
                        </ul>
                        <a routerLink="/blog/o-que-e-anamnese">
                            <h3>O que é anamnese?</h3>
                        </a>
                        <p>A anamnese é uma entrevista utilizada por psicólogos e médicos que possui técnicas para poder estabelecer uma avaliação e diagnóstico do indivíduo. É a base a partir da qual se pode estabelecer um tratamento ou iniciar uma psicoterapia
                            de apoio ou outra qualquer.</p>
                        <a routerLink="/blog/o-que-e-anamnese" class="line-bnt">Leia mais</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog/tempestade-emocional"><img src="assets/images/blog/art7_capa.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>18 de Janeiro de 2021</li>
                            <li>&nbsp;- Por Thais Kohler</li>
                        </ul>
                        <a routerLink="/blog/tempestade-emocional">
                            <h3>Tempestade emocional</h3>
                        </a>
                        <p>Estes comportamentos sempre querem nos dizer algo. Vamos entender melhor? Os pais, em geral, não conseguem avaliar este momento e só querem que a criança pare, mas o que precisamos entender é que as crianças estão em fase de desenvolvimento
                            e cada fase nos traz algo novo e desafiador.</p>
                        <a routerLink="/blog/tempestade-emocional" class="line-bnt">Leia mais</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog/o-trabalho-esta-te-matando"><img src="assets/images/blog/art6_capa.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>15 de Janeiro de 2021</li>
                            <li>&nbsp;- Por Alana Neves</li>
                        </ul>
                        <a routerLink="/blog/o-trabalho-esta-te-matando">
                            <h3>O trabalho está te matando?</h3>
                        </a>
                        <p>O trabalho é uma categoria fundamental à existência humana! É através da atividade do trabalho que o indivíduo transforma os recursos disponíveis na natureza em produtos que atendam às suas necessidades materiais .</p>
                        <a routerLink="/blog/o-trabalho-esta-te-matando" class="line-bnt">Leia mais</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog/atendimento-online-e-permitido"><img src="assets/images/blog/art5_capa.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>15 de Janeiro de 2021</li>
                            <li>&nbsp;- Por Equipe Listen</li>
                        </ul>
                        <a routerLink="/blog/atendimento-online-e-permitido">
                            <h3>Atendimento online é permitido? </h3>
                        </a>
                        <p>Frequentemente surgem dúvidas em relação ao atendimento psicológico online, então, como somos uma plataforma voltada ao atendimento psicológico por videochamada, vamos falar um pouco desta modalidade.</p>
                        <a routerLink="/blog/atendimento-online-e-permitido" class="line-bnt">Leia mais</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog/o-conto-da-bela-adormecida"><img src="assets/images/blog/art4_capa.jpg" alt=""></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>13 de Janeiro de 2021</li>
                            <li>&nbsp;- Por Renara Novais </li>
                        </ul>
                        <a routerLink="/blog/o-conto-da-bela-adormecida">
                            <h3>O conto da Bela Adormecida</h3>
                        </a>
                        <p>Bela Adormecida é um conto que tem várias versões, desde a famosa história criada pelos irmãos Grimm aos populares filmes da Disney. Em todas as versões a história tem a mesma base: a bela filha de um rei, depois de certa idade,
                            cai em um sono profundo ao se ferir com uma máquina de fiar...</p>
                        <a routerLink="/blog/o-conto-da-bela-adormecida" class="line-bnt">Leia mais</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog/saude-mental"><img src="assets/images/blog/art3_capa.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>13 de Janeiro de 2021</li>
                            <li>&nbsp;- Por Equipe Listen</li>
                        </ul>
                        <a routerLink="/blog/saude-mental">
                            <h3>Saúde mental</h3>
                        </a>
                        <p>Saúde mental é um tema que merece atenção. Tanto que o mês de “Janeiro” foi escolhido para promover o “Janeiro Branco”, uma campanha que tem por objetivo mobilizar a sociedade em favor da saúde mental, mudando a compreensão cercada
                            de tabus que existe sobre o tema e promovendo mais possibilidade de saúde mental a todos os indivíduos e à sociedade como um todo.</p>
                        <a routerLink="/blog/saude-mental" class="line-bnt">Leia mais</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog/qual-e-o-papel-do-psicologo"><img src="assets/images/blog/art2_capa.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>12 de Janeiro de 2021</li>
                            <li>&nbsp;- Por Equipe Listen</li>
                        </ul>
                        <a routerLink="/blog/qual-e-o-papel-do-psicologo">
                            <h3>Qual é o papel do psicólogo?</h3>
                        </a>
                        <p>A psicologia oferece uma solução alternativa para os conflitos sociais, emocionais, individuais e elabora um saber sobre si mesmo, sobre o próprio mundo.</p>
                        <a routerLink="/blog/qual-e-o-papel-do-psicologo" class="line-bnt">Leia mais</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog/a-psicologia-e-importante"><img src="assets/images/blog/art1_capa.jpg" alt="A psicologia é importante?"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>11 de Janeiro de 2021</li>
                            <li>&nbsp;- Por Equipe Listen</li>
                        </ul>
                        <a routerLink="/blog/a-psicologia-e-importante">
                            <h3>A psicologia é importante?</h3>
                        </a>
                        <p>Sim, pois ela é a ciência responsável pelo estudo do comportamento das pessoas, do indivíduo, e, sobretudo, porque a Psicologia vem ocupando um espaço de destaque na sociedade.</p>
                        <a routerLink="/blog/a-psicologia-e-importante" class="line-bnt">Leia mais</a>
                    </div>
                </div>
            </div>

            <!--
            <div class="col-lg-12">
                <div class="page-navigation-area">
                    <nav aria-label="Page navigation example text-center">
                        <ul class="pagination">
                            <li class="page-item"><a class="page-link page-links" routerLink="/blog"><i class='bx bx-chevrons-left'></i></a></li>
                            <li class="page-item active"><a class="page-link" routerLink="/blog">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog"><i class='bx bx-chevrons-right'></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            -->
        </div>
    </div>
</section>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Ativação de conta</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div class="error-area ptb-100 container">
    <div class="error-page ">
        <div *ngIf='waiting'>
            <p>Por favor, aguarde alguns instantes emnquanto estamos validando e ativando a sua conta na plataforma...</p>
        </div>
        <div *ngIf='processed'>
            <h3>Ativação de conta!</h3>
            <div *ngIf='success' class="mt-5">
                <div *ngIf='type=="PRO"' class="text-left">
                    <p>Seu cadastro de usuário para acesso à plataforma Listen foi confirmado!</p>
                    <p>Agora é só acessar <b><a class="" (click)='callLogin()'>https://app.listenonline.com.br</a></b> e fazer o login
                        com o usuário e senha que você acabou de criar para fazer uso da área restrita Listen.</p>
                    <p><b>Primeiros passos</b></p>
                    <p>Após fazer o login, você poderá conferir nossa base de conhecimento com todas as explicações e
                        esclarecimentos para as suas dúvidas iniciais.</p>
                    <p><b>Preenchimento do perfil</b></p>
                    <p>Clicando no menu Perfil, você poderá conferir os dados pessoais informados em seu cadastro
                        inicial, e deverá complementar seus dados informando a sua Formação, Redes Sociais,
                        Apresentação, Público que atende, Especialidades e Valor para consulta, além de anexar o seu
                        CPF, RG, CRP e e-Psi digitalizados.<br>
                        Após, leia e, se concordar, aceite o nosso Termo de Adesão, que descreve os Termos Condições
                        Gerais de Uso de nossa plataforma e descreve a nossa relação.</p>
                    <p><b>Aprovação do perfil</b></p>
                    <p>Depois de completar o preenchimento de seu perfil, anexar os documentos e aceitar o Termo de
                        Adesão, você deve clicar em ENVIAR PARA APROVAÇÃO no box Situação de Cadastro. Vamos analisar as
                        suas informações e, se tudo estiver de acordo, seu cadastro estará aprovado em até 24 horas.</p>
                    <p><b>E depois?</b></p>
                    <p>A partir deste momento, seu perfil estará disponível na pesquisa pelos usuários, que poderão
                        marcar as consultas online de acordo com a sua disponibilidade de agendamento.</p>
                    <p>Qualquer dúvida, por favor entre em contato: <a href="contato@listenonline.com.br">contato@listenonline.com.br</a> </p>
                </div>
                <div *ngIf='type=="PRO"'>
                    <a class="mt-4 default-btn page-btn box-btn" (click)='callLogin()'>Acessar</a>
                </div>

                <div *ngIf='type=="CUS"' class="text-left">
                    <p>Seu cadastro de usuário para acesso à plataforma Listen foi confirmado!</p>
                    <p>Agora é só acessar <b><a class="" (click)='callLogin()'>https://app.listenonline.com.br</a></b> e fazer o login
                        com o usuário e senha que você acabou de criar para fazer uso da área restrita Listen.</p>
                    <p>Qualquer dúvida, por favor entre em contato: <a href="contato@listenonline.com.br">contato@listenonline.com.br</a> </p>
                </div>
                <div *ngIf='type=="CUS"'>
                    <a class="mt-4 default-btn page-btn box-btn" (click)='callLogin()'>Acessar</a>
                </div>
            </div>

            <div *ngIf='!success' class="mt-5">
                <p>Ops! A sua conta já foi ativada ou o link que foi enviado expirou ou foi digitado de forma incorreta!
                </p>
                <p>Verifique o seu acesso na área restrita clicando abaixo e fazendo login com o e-mail e senha que você
                    usou em seu cadastro.</p>
                <a class="mt-4 default-btn page-btn box-btn" (click)='callLogin()'>Acessar</a>
            </div>
        </div>
    </div>
</div>
<div class="single-testimonials">
    <div class="testimonials-head">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-12">
                <div class="text-center"> 
                    <div class="testimonials-img text-center" style="max-width: 231px; display: inline-block;">
                        <img src="{{pro.profile_img_url}}" >
                    </div>
                </div>
                <div class='price'>
                    {{pro.appointment_value | currency:'BRL'}}
                </div>
                <div class='time'><b>50 min</b></div>
                <div class='text-center' *ngIf='pro.free_anamnesis'>
                    <div class='audiences'>Anamnese Gratuita</div>
                </div>
                <div class='content'>
                    <ul class='rate'>
                        <li *ngIf='pro.video_url.length > 8' ><button class="js-video-button-{{pro.id}}" style="background-color: transparent;" (click)='showVideo()' [attr.data-video-id]="pro.video_url"><i class="bx bxl-youtube" style="margin: 4px;"></i></button></li>
                        <li *ngIf='pro.linkedin_profile_url?.length > 3'> <a href='{{pro.linkedin_profile_url}}' target="_blank"><i class='bx bxl-linkedin' style="margin: 4px;"></i></a></li>
                        <li *ngIf='pro.facebook_profile_url?.length > 3'> <a href='{{pro.facebook_profile_url}}' target="_blank"><i class='bx bxl-facebook' style="margin: 4px;"></i></a></li>
                        <li *ngIf='pro.instagram_profile_url?.length > 3'> <a href='{{pro.instagram_profile_url.toString().replace("@", "https://www.instagram.com/")}}' target="_blank"><i class="bx bxl-instagram" style="margin: 4px;"></i></a></li>

                    </ul>

                </div>
                <div class="content mt-3" *ngIf='pro.evaluation_qty > 0'>
                    <ul class="rate">
                        <li> <i class="bx bxs-star"></i></li>
                        <li *ngIf='pro.evaluation_avg > 1'> <i class="bx bxs-star"></i></li>
                        <li *ngIf='pro.evaluation_avg > 2'> <i class="bx bxs-star"></i></li>
                        <li *ngIf='pro.evaluation_avg > 3'> <i class="bx bxs-star"></i></li>
                        <li *ngIf='pro.evaluation_avg > 4'> <i class="bx bxs-star"></i></li>
                    </ul>
                    <div class="text-center">({{ pro.evaluation_qty }})</div>
                </div>

                <div class="details mb-20" style="margin-top: 20px;" *ngIf='mode=="Simple"'>
                    <button class="default-btn page-btn box-btn" [disabled]='sending' (click)='showDetails(pro)'>Ver Detalhes</button>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-12">
                <div class="content">
                    <h2>{{pro.name}}</h2>
                    <span>{{pro.professional_type_name}}</span>
                    <h3>Registro {{pro.registration_number}}</h3>

                    <div class='audiences-row'>
                        <div class='audiences' *ngFor='let data of pro.audiences'>{{data.name}}</div>
                    </div>

                    <div class='specialties-row'>
                        <div class='specialties' *ngFor='let data of pro.specialties'>{{data.name}}</div>
                    </div>

                    <div [ngxSummernoteView]='pro.curriculum' class="info curriculum {{ mode == 'Complete' ? '' : 'text-limited' }}"></div>

                    <div *ngIf='mode == "Complete"' class="mt-20">
                        <h3>Formação</h3>
                        <div *ngFor='let data of pro.educations'>
                            <div><b>{{data.education_type_name}} - {{data.course_name}} </b></div>
                            <div>{{data.institution_name}}</div>
                            <div>{{data.description}}</div>
                            <div>Período: {{data.start_year}} a {{data.end_year}} </div>
                            <div class='specialization-row'>
                                <div class='specialization' *ngFor='let d of data.specialization_areas'>{{d.name}}</div>
                            </div>
                        </div>
                        <a *ngIf="pro.curriculum_lattes != ''" href="{{pro.curriculum_lattes}}" target="blank">Currículo Lattes</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-12 mt-3">
                <div class="content">
                    <h3>
                        <button type="submit" id="btn-show-{{pro.id}}" class="default-btn page-btn box-btn agenda-nav" (click)='toggleAgenda(pro.id)'><i class="bx bx-chevrons-down"></i></button>
                        <button type="submit" id="btn-hide-{{pro.id}}" style="display: none" class="default-btn page-btn box-btn agenda-nav" (click)='toggleAgenda(pro.id)'><i class="bx bx-chevrons-up"></i></button>
                        <span (click)='toggleAgenda(pro.id)' style="font-size: 21px; cursor: pointer;">Ver Disponibilidades</span>
                    </h3>

                    <div id="agenda-{{pro.id}}" style="display: none">
                        <div class="content" *ngIf='pro.screenAvailableTimes.length > 0'>
                            <div class="message-box warning" style="margin-bottom: 1em;">
                                <p>Para agendar uma consulta, escolha o horário desejado abaixo e clique para selecionar. Se preferir, use as setas abaixo para navegar e avançar/retroceder entre as semanas.</p>
                            </div>
                            <div class="agenda-container" style="padding-left: 2px; padding-right: 2px;">
                                <div class='week row'>
                                    <div *ngFor='let day of pro.days' class='col p-0'>
                                        <div class='dow'>{{day | date:'E'}}</div>
                                    </div>
                                </div>
                                <div class='row'>
                                    <div *ngFor='let day of pro.days' class='col p-0'>
                                        <div class='weekday'>{{day | date:'dd/MM'}}</div>
                                    </div>
                                </div>

                                <div class='available-date row'>
                                    <div *ngFor='let d of pro.days' class='col' style="padding-left: 2px; padding-right: 2px;">
                                        <div></div>
                                        <div *ngFor='let day of pro.screenAvailableTimes' class='col' style="padding-left: 2px; padding-right: 2px;">
                                            <div *ngIf='day.date.getDay() == d.getDay() '>
                                                <div *ngFor='let time of day.times'>
                                                    <div class='day time' [ngClass]="{active: itsTime(time, pro)}"
                                                     (click)='markDay(time, pro)'>{{time | date:'HH:mm'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--div *ngIf='selectedProfessional?.id == pro.id'>
                                <div class="section-title">
                                    <span>AGENDAMENTO</span>
                                    <h2>Confirme aqui o seu agendamento</h2>
                                    <p>Para confirmar o agendamento deste horário com o profissional selecionado, você precisa confirmar o pagamento, acessando o aplicativo Listen Online.</p>
                                    <p>Se você já é cadastrado, clique no botão <b>Fazer Login</b>. Caso ainda não tenha se cadastrado, clique no botão <b>Cadastre-se</b>: é simples e rápido.</p>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6 col-sm-6 col-6 text-right">
                                        <div class="form-group">
                                            <button type="submit" class="default-btn page-btn box-btn" [disabled]='sending'
                                                    (click)='callLogin()'>Fazer Login</button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 col-6 text-left">
                                        <div class="form-group">
                                            <button type="submit" class="default-btn page-btn box-btn" [disabled]='sending'
                                                    (click)='callRegister()'>Cadastrar-se</button>
                                        </div>
                                    </div>
                                </div>
                            </div-->
                        </div>
                        <div class="content text-center" *ngIf='pro.screenAvailableTimes.length == 0'>
                            Nenhuma agenda disponível no período!
                        </div>

                        <div class='row mt-20'>
                            <div class="col-lg-6 col-sm-6 col-6">
                                <div class="form-group" *ngIf='checkDate(pro.days[0])'>
                                    <button type="submit" class="default-btn page-btn box-btn week-nav"
                                            (click)='previousWeek(pro)'><i class="bx bx-chevrons-left"></i></button>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 text-right">
                                <div class="form-group">
                                    <button type="submit" class="default-btn page-btn box-btn week-nav"
                                            (click)='nextWeek(pro)'><i class="bx bx-chevrons-right"></i></button>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </div>
        </div>
    </div>
</div>